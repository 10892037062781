import { boardsListAPI } from "../API/api";
import { mapObj } from "../utils/objectHelpers";

const SET_BOARDS = 'SET_BOARDS';
const ADD_BOARD = 'ADD_BOARD';
const EDIT_BOARD = 'EDIT_BOARD';
// const DUPLICATE_BOARD = 'DUPLICATE_BOARD';
// const COPY_BOARD = 'COPY_BOARD';
const DELETE_BOARD = 'DELETE_BOARD';

let initialState = {
  boards: [],
  isFetching: false,
};

export const boardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOARDS:
      return {
        ...state,
        boards: action.boards,
      }
    case ADD_BOARD:
      return {
        ...state,
        boards: [...state.boards, action.newItem],
      }
    case EDIT_BOARD:
      return {
        ...state,
        boards: mapObj(state.boards, action.boardId, 'id', action.newObj)
      }
    case DELETE_BOARD:
      return {
        ...state,
        boards: state.boards.filter((el) => el.id !== action.boardId)
      }
    default: return { ...state };
  }
}

const setBoards = (boards) => ({ type: SET_BOARDS, boards });
const setAddBoard = (newItem) => ({ type: ADD_BOARD, newItem });
const setEditBoard = (boardId, newObj) => ({ type: EDIT_BOARD, boardId, newObj });
const setDeleteBoard = (boardId) => ({ type: DELETE_BOARD, boardId });

export const getBoards = (id) => async (dispatch) => {
  await boardsListAPI.getBoardsList(id)
    .then(response => dispatch(setBoards(response.data)))
    .catch(err => console.log(err))
}

export const addBoard = ({
  name: name,
  project: project
}) => async (dispatch) => {
  await boardsListAPI.addBoard({
    name: name,
    project: project
  })
    .then(response => dispatch(setAddBoard(response.data)))
    .catch(err => console.log(err))
}

export const editBoard = (
  {
    boardId: boardId,
    name: name,
    archived: archived,
  }
) => async (dispatch) => {
  await boardsListAPI.editBoard(
    {
      boardId: boardId,
      name: name,
      archived: archived,
    }
  )
    .then(response => dispatch(setEditBoard(boardId, response.data)))
    .catch(err => console.log(err))
}

export const deleteBoard = (id) => async (dispatch) => {
  await boardsListAPI.deleteBoard(id)
    .then(() => dispatch(setDeleteBoard(id)))
}

export const duplicateBoard = (
  {
    boardId: boardId,
  }
) => async (dispatch) => {
  await boardsListAPI.duplicateBoard(
    {
      boardId: boardId,
    }
  )
    .then(response => dispatch(setAddBoard(response.data)))
    .catch(err => console.log(err))
}

export const sendBoard = (
  {
    boardId: boardId,
    projectId: projectId,
  }
) => async () => {
  await boardsListAPI.moveBoard(
    {
      boardId: boardId,
      projectId: projectId,
    }
  )
    .catch(err => console.log(err))
}
