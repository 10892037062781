import { projectItemAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_PLAN = 'SET_PLAN';
const ADD_PLAN_ITEM = 'ADD_PLAN_ITEM';
const EDIT_PLAN_ITEM = 'EDIT_PLAN_ITEM';
const EDIT_PLAN_STATUS = 'EDIT_PLAN_STATUS';
const EDIT_PLAN_FACTDATE = 'EDIT_PLAN_FACTDATE';
const DELETE_PLAN_ITEM = 'DELETE_PLAN_ITEM';
const TOGGLE_FILTERED = 'TOGGLE_FILTERED'

let initialState = {
  projectPlan: [],
  isFetching: false,
  isFiltered: false,
};

export const projectPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        projectPlan: action.projectPlan,
      }
    case ADD_PLAN_ITEM:
      return {
        ...state,
        projectPlan: [...state.projectPlan, action.newItem],
      }
    case EDIT_PLAN_ITEM:
      return {
        ...state,
        projectPlan: mapObj(state.projectPlan, action.planId, 'id', action.newObj)
      }
    case EDIT_PLAN_STATUS:
      return {
        ...state,
        projectPlan: mapObj(state.projectPlan, action.planId, 'id', { status: action.field })
      }
    case EDIT_PLAN_FACTDATE:
      return {
        ...state,
        projectPlan: mapObj(state.projectPlan, action.planId, 'id', { fact_date: action.field })
      }
    case DELETE_PLAN_ITEM:
      return {
        ...state,
        projectPlan: state.projectPlan.filter((el) => el.id !== action.planId)
      }
    case TOGGLE_FILTERED: {
      return {
        ...state,
        isFiltered: action.isFiltered
      }
    }
    default: return { ...state };
  }
}

const setPlan = (projectPlan) => ({ type: SET_PLAN, projectPlan });
const setAddPlan = (newItem) => ({ type: ADD_PLAN_ITEM, newItem });
const setEditPlan = (planId, newObj) => ({ type: EDIT_PLAN_ITEM, planId, newObj });
const setEditPlanStatus = (planId, field) => ({ type: EDIT_PLAN_STATUS, planId, field });
const setEditPlanFactdate = (planId, field) => ({ type: EDIT_PLAN_FACTDATE, planId, field });
const setDeletePlan = (planId) => ({ type: DELETE_PLAN_ITEM, planId });
const toggleFiltered = (isFiltered) => ({ type: TOGGLE_FILTERED, isFiltered });

// Calendar plan
export const getProjectPlan = (id) => async (dispatch) => {
  await projectItemAPI.getProjectPlan(id)
    .then(response => dispatch(setPlan(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(false));
  sessionStorage.removeItem(`planFiltered${id}`);
  sessionStorage.removeItem(`planFilterDatas${id}`);
}

export const addPlanItem = (
  {
    project: project,
    event: event,
    plan_date: plan_date,
    attribute: attribute,
    status: status,
    notification: notification,
  }
) => async (dispatch) => {
  await projectItemAPI.addPlanItem(
    {
      project: project,
      event: event,
      plan_date: plan_date,
      attribute: attribute,
      status: status,
      notification: notification,
    }
  )
    .then(response => dispatch(setAddPlan(response.data)))
    .catch(err => console.log(err))
}
export const editPlanItem = (
  {
    id: id,
    event: event,
    plan_date: plan_date,
    fact_date: fact_date,
    attribute: attribute,
    status: status,
    notification: notification,
  }
) => async (dispatch) => {
  await projectItemAPI.editPlanItem(
    {
      id: id,
      event: event,
      plan_date: plan_date,
      fact_date: fact_date,
      attribute: attribute,
      status: status,
      notification: notification,
    }
  )
    .then(response => dispatch(setEditPlan(id, response.data)))
    .catch(err => console.log(err))
}
export const editPlanStatus = (
  {
    id: id,
    status: status,
  }
) => async (dispatch) => {
  await projectItemAPI.editPlanStatus(
    {
      id: id,
      status: status,
    }
  )
    .then((response) => dispatch(setEditPlanStatus(id, response.data.status)))
    .catch(err => console.log(err))
}
export const editPlanFactdate = (
  {
    id: id,
    fact_date: fact_date,
  }
) => async (dispatch) => {
  await projectItemAPI.editPlanFactDate(
    {
      id: id,
      fact_date: fact_date,
    }
  )
    .then((response) => dispatch(setEditPlanFactdate(id, response.data.fact_date)))
    .catch(err => console.log(err))
}

export const deletePlanItem = (id) => async (dispatch) => {
  await projectItemAPI.deletePlanItem(id)
    .then(() => dispatch(setDeletePlan(id)))
}

export const getFilteredPlan = (
  {
    projectId: projectId,
    plan_date: plan_date,
    fact_date: fact_date,
    attributes: attribute,
    statuses: statuses,
  }
) => async (dispatch) => {
  await projectItemAPI.filterPlan(
    {
      projectId: projectId,
      plan_date: plan_date,
      fact_date: fact_date,
      attributes: attribute,
      statuses: statuses,
    }
  )
    .then(response => dispatch(setPlan(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(true))
  sessionStorage.setItem(`planFiltered${projectId}`, true);
  sessionStorage.setItem(`planFilterDatas${projectId}`, JSON.stringify({
    plan_date: plan_date,
    fact_date: fact_date,
    attributes: attribute,
    statuses: statuses,
  }))
}
