import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import CommentIcon from '../../../../../../svgIcons/CommentIcon';
import PauseIcon from '../../../../../../svgIcons/PauseIcon';
import PlayIcon from '../../../../../../svgIcons/PlayIcon';
import styles from './taskitem.module.css';

export default function TaskItem(props) {

  const [timerStart, setTimerStart] = useState(false)

  return (
    <div className={classNames(styles.item)}>
      <p className={styles.name}>
        {props.item.name}
      </p>
      <div className={styles.path}>
        {props.item.path?.project}&nbsp;/&nbsp;
        {props.item.path?.board}&nbsp;/&nbsp;
        {props.item.path?.column}
      </div>
      <div className={classNames('flex', 'iconBtnGroup', styles.btns)}>
        {
          props.item.comments?.length > 0 &&
          <div className={classNames('flex', 'iconBtn', styles.comments)}>
            {props.item.comments?.length}
            <CommentIcon />
          </div>
        }
        {
          timerStart ?
            <button
              onClick={() => setTimerStart(!timerStart)}
              className={classNames('flex', 'iconBtn')}
            >
              <PauseIcon />
            </button>
            :
            <button
              onClick={() => setTimerStart(!timerStart)}
              className={classNames('flex', 'iconBtn')}
            >
              <PlayIcon />
            </button>
        }
      </div>
    </div >
  );
}
