import classNames from 'classnames';
import React from 'react';
import styles from './taskheader.module.css';

export default function TaskHeader(props) {

  return (
    <div className={classNames('flex', styles.taskHeader)}>
      <div className={classNames('flex', 'tagsWrapper')}>
        {props.tags?.map((item, index) =>
          <span
            key={index}
            style={{ 'backgroundColor': `${item.color}` }}
            className={classNames('tag', Boolean(item.color) && 'tagWhite')}
          >
            #{item.name}
          </span>
        )}
      </div>
    </div>
  );
}
