import React from 'react';

export default function LinkIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2049_477)">
        <path d="M10.3842 12.9487L7.93799 15.3949C6.44567 16.8473 4.05853 16.815 2.60611 15.3227C1.18171 13.8592 1.1814 11.5277 2.60538 10.0638L5.05158 7.61533C5.34439 7.3223 5.34422 6.8474 5.05119 6.55458C4.75816 6.26176 4.28326 6.26194 3.99044 6.55497L1.54498 9.00342C-0.512794 11.0622 -0.511951 14.3994 1.54684 16.4572C3.60563 18.515 6.9428 18.5141 9.0006 16.4553L11.4468 14.0091C11.7345 13.7112 11.7263 13.2365 11.4284 12.9488C11.1378 12.6681 10.677 12.6681 10.3864 12.9488H10.3842V12.9487Z" fill="#3E5196" />
        <path d="M16.4577 1.54559C15.472 0.553137 14.1301 -0.00346143 12.7313 1.8627e-05C11.3334 -0.0037075 9.99194 0.551731 9.00579 1.5426L6.55582 3.98954C6.26279 4.28236 6.26262 4.75726 6.55544 5.05029C6.84825 5.34332 7.32316 5.34349 7.61619 5.05068L10.0646 2.60448C10.7698 1.89503 11.7295 1.49729 12.7298 1.49985C14.8122 1.50056 16.4998 3.18923 16.4991 5.27164C16.4988 6.27109 16.1017 7.22955 15.395 7.93635L12.9488 10.3826C12.6558 10.6756 12.6558 11.1507 12.9488 11.4437C13.2418 11.7367 13.7169 11.7367 14.0099 11.4437L16.4561 8.99896C18.511 6.93961 18.5117 3.60571 16.4577 1.54559Z" fill="#3E5196" />
        <path d="M10.7201 6.21904L6.22064 10.7185C5.92272 11.0062 5.9145 11.481 6.20222 11.7789C6.48994 12.0768 6.9647 12.085 7.26258 11.7973C7.26884 11.7912 7.27495 11.7851 7.281 11.7789L11.7805 7.2794C12.0682 6.98149 12.0599 6.50676 11.762 6.21904C11.4715 5.93838 11.0107 5.93838 10.7201 6.21904Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2049_477">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
