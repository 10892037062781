import React from 'react';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import Preloader from '../../../common/Preloader/Preloader';

const DashBoard = lazy(() => import('./DashBoard'));

export default function DashBoardContainer(props) {

  const me = useSelector(selectMe)

  return (
    <Suspense fallback={<Preloader />}>
      <DashBoard
        me={me}
      />
    </Suspense>
  );
}
