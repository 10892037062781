import React, { useState } from 'react';
import styles from './filescontrols.module.css';
import classNames from 'classnames';
import DeleteIcon from '../../../../svgIcons/DeleteIcon';
import CheckedIcon from '../../../../svgIcons/CheckedIcon';


export default function FilesControls(props) {

  return (
    <div className={styles.filesControls}>
      <div
        className={classNames(
          'flex',
          'shadow',
          styles.controlsList,
        )}
      >
        <button
          className={classNames('flex', 'iconBtn')}
          onClick={() => props.handleDeleteFile(props.item)}
        >
          <DeleteIcon /> Удалить
        </button>
        {
          props.image &&
          <button
            className={classNames('flex', 'iconBtn')}
            onClick={() => props.handleBanner(props.item)}
          >
            <CheckedIcon /> Сделать обложкой
          </button>
        }
      </div>
    </div >
  );
}
