import React from 'react';
import styles from './commentitem.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CommentFiles from './CommentFiles/CommentFiles';
import NeedleTrueIcon from '../../../../../svgIcons/NeedleTrueIcon';
import UserInfo from '../../../UserInfo/UserInfo';
import { editDateToFull, editTime } from '../../../../../utils/dateEditor';
import PopupControls from '../../../PopupControls/PopupControls';
import { NavLink } from 'react-router-dom';
import { parseHTML } from '../../../../../utils/parseHtml';
import { deleteCommentItem } from '../../../../../redux/ProjectPage/projectCommentsReducer';
import ConfirmWindow from '../../../ConfirmWindow/ConfirmWindow';

export default function CommentItemContent(props) {

  const dispatch = useDispatch();

  const [isShowControls, setIsShowControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);

  const handleEdit = () => {
    props.setIsEdit(true);
  }

  // const filtered = JSON.parse(sessionStorage.getItem(`commentsFiltered${props.item.project}`));
  // const filteredData = JSON.parse(sessionStorage.getItem(`commentsFilteredDatas${props.item.project}`));

  // const handleEditPinned = () => {
  //   dispatch(editCommentPinned({
  //     id: props.item.id,
  //     pinned: props.item.pinned ? false : true,
  //   })
  //   )
  //     .then(() => {
  //       if (filtered) {
  //         dispatch(getFilteredComments(
  //           {
  //             projectId: props.targetId,
  //             date: filteredData?.date,
  //             for_all: filteredData?.for_all,
  //             hidden: filteredData?.hidden,
  //             personal: filteredData?.personal,
  //           }
  //         ))
  //       } else {
  //         dispatch(getProjectComments(props.targetId))
  //       }
  //     })
  // }

  const handleClickOpenConfirm = () => {
    setIsOpenConfirmPopup(true)
    !props.insideParent && document.body.classList.add('modal-show');
  }

  const handleClickCloseConfirm = () => {
    setIsOpenConfirmPopup(false)
    !props.insideParent && document.body.classList.remove('modal-show');
    setIsShowControls(false)
  }

  const handleDeleteItem = () => {
    props.handleDelete(props.item.id)
    handleClickCloseConfirm()
  }

  return (
    <div
      onMouseEnter={() => !props.readOnly && setIsShowControls(true)}
      onMouseLeave={() => !props.readOnly && setIsShowControls(false)}
      className={classNames('flex', styles.comment)}
    >
      <div className={classNames('flex', styles.author)}>
        <NavLink
          to={`/user/${props.item.author?.id}`}
          className={classNames('flex')}
        >
          <UserInfo
            avatar={props.item.author?.avatar}
            first_name={props.item.author?.first_name}
            last_name={props.item.author?.last_name}
            email={props.item.author?.email}
            isFull={false}
            color={props.item.author?.avatar_color}
          />
        </NavLink>
        <div className={classNames(styles.authorName, styles.mobile)}>
          {props.item.author?.first_name} {props.item.author?.last_name}
        </div>
      </div>
      <div className={styles.commentContent}>
        <div className={classNames(styles.authorName, styles.desktop)}>
          {props.item.author?.first_name} {props.item.author?.last_name}
        </div>
        {
          props.item.text && (props.item.text !== '<p></p>' && props.item.text !== '<p><br></p>') &&
          <div className={styles.commentTextWrapper}>
            <div className={styles.commentText}>
              {parseHTML(props.item.text)}
            </div>
          </div>
        }
        {
          props.item?.files?.length > 7 ?
            <>
              <p
                className={styles.showFilesBtn}
                onClick={props.handleShowFiles}
              >
                {props.isShowFiles ? 'Скрыть вложения' : 'Показать вложения' + ' ' + `${'(' + props.item?.files?.length + 'шт)'}`}
              </p>
              {
                props.isShowFiles &&
                <CommentFiles
                  filesList={props.item?.files}
                  insideParent={props.insideParent}
                />
              }
            </>
            :
            props.item?.files?.length > 0 ?
              <CommentFiles
                filesList={props.item?.files}
                insideParent={props.insideParent}
              />
              :
              null
        }
      </div>
      <div className={styles.commentMeta}>
        <span className={styles.date}>
          {editDateToFull(props.item.timestamp)}
        </span>
        <span className={styles.time}>
          {editTime(props.item.timestamp)}
        </span>
      </div>
      {
        isShowControls &&
        <PopupControls
          handleClickOpenConfirm={handleClickOpenConfirm}
          handleClickCloseConfirm={handleClickCloseConfirm}

          setIsShowControls={setIsShowControls}
          forComment={true}
          pinned={props.item.pinned}
          hasPinned={props.hasPinned}
          handlePin={() => props.handleEditPinned(props.item.id, props.item.pinned)}
          handleEdit={handleEdit}
          insideParent={props.insideParent}
        />
      }
      {
        (props.hasPinned && props.item.pinned) &&
        <div className={classNames('flex', styles.isPin)}>
          <NeedleTrueIcon />
        </div>
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          title={
            props.forComment ? 'Удалить комментарий?' : 'Удалить?'
          }
          insideParent={props.insideParent}
          onDelete={handleDeleteItem}
          closeDelete={handleClickCloseConfirm}
        />
      }
    </div >
  );
}
