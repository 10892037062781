import { chatAPI } from "../../API/api";

const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY';
const CREATE_MESSAGE = 'CREATE_MESSAGE';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';

let initialState = {
  chat: [],
  isFetching: false
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_HISTORY:
      return {
        ...state,
        chat: action.chat,
      }
    case CREATE_MESSAGE:
      return {
        ...state,
        chat: [...state.chat, ...action.messages],
      }
    case TOGGLE_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching
      }
    }
    default: return { ...state };
  }
}

const setChat = (chat) => ({ type: SET_CHAT_HISTORY, chat });
const createMessage = (messages) => ({ type: CREATE_MESSAGE, messages });
const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });

export const getChatHistory = () => async (dispatch) => {
  dispatch(toggleIsFetching(true))

  await chatAPI.getChat()
    .then(response => dispatch(setChat(response.data)))
    .catch(err => console.log(err))
    .finally(() => dispatch(toggleIsFetching(false)))
}

export const sendMessage = ({
  content: content,
  projectId: projectId,
  boardId: boardId,
  taskId: taskId
}) => async (dispatch) => {
  dispatch(toggleIsFetching(true))

  await chatAPI.createChatMessage({
    content: content,
    projectId: projectId,
    boardId: boardId,
    taskId: taskId
  })
    .then(response => dispatch(createMessage(response.data)))
    .catch(err => console.log(err))
    .finally(() => dispatch(toggleIsFetching(false)))
}

