import React, { useState } from 'react';
import styles from './fileslist.module.css';
import classNames from 'classnames';
import { editFileName, editLongFileName } from '../../../utils/fileNameEditor';
import FileIcon from '../../../svgIcons/FileIcon';
import ImgPopup from '../ImgPopup/ImgPopup';
import File from './File/File';

export default function FilesList(props) {

  const [isOpenFullImg, setIsOpenFullImg] = useState(false);
  const [link, setLink] = useState('');

  const handleClickOpenImg = () => {
    setIsOpenFullImg(true)
    props.insideParent && document.body.classList.add('modal-show');
  }
  const handleClickCloseImg = () => {
    setIsOpenFullImg(false)
    props.insideParent && document.body.classList.remove('modal-show');
  }

  return (
    <div className={styles.filesWrapper}>
      <div className={classNames('flex', styles.files, styles.docs)}>
        {
          props.filesList?.filter(item => item.external_url).map((item) =>
            <File
              fileWithControls={props.fileWithControls}
              key={item.id}
              external={true}
              item={item}
              editFile={props.editFile}
              handleDeleteFile={props.handleDeleteFile}
              handleBanner={props.handleBanner}
              readOnly={props.readOnly}
            />
          )
        }
      </div>
      <div className={classNames('flex', styles.files, styles.docs)}>
        {
          props.filesList?.filter(item => !['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).filter(item => !item.external_url).map((item) =>
            <File
              fileWithControls={props.fileWithControls}
              key={item.id}
              doc={true}
              item={item}
              editFile={props.editFile}
              handleDeleteFile={props.handleDeleteFile}
              handleBanner={props.handleBanner}
              readOnly={props.readOnly}
            />
          )
        }
      </div>
      <div className={classNames('flex', styles.files, styles.images)}>
        {
          props.filesList?.filter(item => ['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).filter(item => !item.external_url).map((item) =>
            <File
              fileWithControls={props.fileWithControls}
              key={item.id}
              image={true}
              item={item}
              editFile={props.editFile}
              handleClickOpenImg={handleClickOpenImg}
              setLink={setLink}
              handleDeleteFile={props.handleDeleteFile}
              handleBanner={props.handleBanner}
              readOnly={props.readOnly}
            />
          )
        }
        {
          isOpenFullImg &&
          <ImgPopup
            insideParent={props.insideParent}
            file={link}
            handleClickClose={handleClickCloseImg}
          />
        }
      </div>
    </div>
  );
}


// import React, { useState } from 'react';
// import styles from './fileslist.module.css';
// import classNames from 'classnames';
// import { editFileName, editLongFileName } from '../../../utils/fileNameEditor';
// import FileIcon from '../../../svgIcons/FileIcon';
// import ImgPopup from '../ImgPopup/ImgPopup';

// export default function FilesList(props) {

//   const [isOpenFullImg, setIsOpenFullImg] = useState(false);
//   const [link, setLink] = useState('');

//   const handleClickOpenImg = () => {
//     setIsOpenFullImg(true)
//     document.body.classList.add('modal-show');
//   }
//   const handleClickCloseImg = () => {
//     setIsOpenFullImg(false)
//     document.body.classList.remove('modal-show');
//   }

//   return (
//     <div className={styles.filesWrapper}>
//       <div className={classNames('flex', styles.files, styles.docs)}>
//         {
//           props.filesList?.filter(item => item.external_url).map((item) =>
//             <div
//               key={item.id}
//               className={classNames('flex', styles.file)}
//             >
//               <a
//                 href={item.external_url}
//                 target="_blank"
//                 rel="noreferrer"
//                 className={classNames('flex', styles.fileLink)}>
//                 {!props.editFile && <FileIcon />} {editLongFileName(item.name)}
//               </a>
//               {
//                 props.editFile &&
//                 <button
//                   onClick={() => props.handleDeleteFile(props.filesList, item)}
//                   type="button"
//                   className={classNames('flex', styles.deleteFileBtn)}
//                 >
//                   <span className={classNames(styles.horLine, styles.line)}></span>
//                   <span className={classNames(styles.verLine, styles.line)}></span>
//                 </button>
//               }
//             </div>
//           )
//         }
//       </div>
//       <div className={classNames('flex', styles.files, styles.docs)}>
//         {
//           props.filesList?.filter(item => !['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).filter(item => !item.external_url).map((item) =>
//             <div
//               key={item.id}
//               className={classNames('flex', styles.file)}
//             >
//               <a
//                 href={item.file}
//                 target="_blank"
//                 download
//                 rel="noreferrer"
//                 className={classNames('flex', styles.fileLink)}>
//                 {!props.editFile && <FileIcon />} {editLongFileName(item.name)}
//               </a>
//               {
//                 props.editFile &&
//                 <button
//                   onClick={() => props.handleDeleteFile(props.filesList, item)}
//                   type="button"
//                   className={classNames('flex', styles.deleteFileBtn)}
//                 >
//                   <span className={classNames(styles.horLine, styles.line)}></span>
//                   <span className={classNames(styles.verLine, styles.line)}></span>
//                 </button>
//               }
//             </div>
//           )
//         }
//       </div>
//       <div className={classNames('flex', styles.files, styles.images)}>
//         {
//           props.filesList?.filter(item => ['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).filter(item => !item.external_url).map((item) =>
//             <div
//               key={item.id}
//               className={classNames('flex', 'imageBox', styles.image, props.editFile ? styles.editImg : styles.openImg)}
//               onClick={() => {
//                 !props.editFile && setLink(item.file)
//                 !props.editFile && handleClickOpenImg()
//               }}
//             >
//               <img
//                 src={item.file}
//               />
//               {
//                 props.editFile &&
//                 <button
//                   onClick={() => props.handleDeleteFile(props.filesList, item)}
//                   type="button"
//                   className={classNames('flex', styles.deleteFileBtn)}
//                 >
//                   <span className={classNames(styles.horLine, styles.line)}></span>
//                   <span className={classNames(styles.verLine, styles.line)}></span>
//                 </button>
//               }
//             </div>
//           )
//         }
//         {
//           isOpenFullImg &&
//           <ImgPopup
//             insideParent={true}
//             file={link}
//             handleClickClose={handleClickCloseImg}
//           />
//         }
//       </div>
//     </div>
//   );
// }
