import classNames from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import { generateKey } from '../../../../../utils/generateKey';
import TaskItem from './TaskItem/TaskItem';
import styles from './tasklist.module.css';

export default function TaskList(props) {

  const tasks = [
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №1",
        board: "Доска 1",
        column: "Колонка 1"
      },
      comments: [
        1, 2, 3, 4, 5, 6
      ]
    },
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №2",
        board: "Доска 2",
        column: "Колонка 2"
      },
      comments: [
        1, 2, 3
      ]
    },
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №3",
        board: "Доска 3",
        column: "Колонка 3"
      },
      comments: [
        1, 2, 3, 4, 5
      ]
    },
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №4",
        board: "Доска 4",
        column: "Колонка 4"
      },
      comments: [
        1, 2, 3, 4, 5
      ]
    },
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №5",
        board: "Доска 5",
        column: "Колонка 5"
      },
      comments: null
    },
    {
      id: generateKey(),
      name: "Далеко-далеко, за словесными горами в стране гласных и согласных живут рыбные тексты. Которой скатился, заголовок жаренные приставка заманивший строчка страна дорогу моей там снова даль имеет пояс переулка безорфографичный подзаголовок если продолжил?",
      path: {
        project: "Объект №6",
        board: "Доска 6",
        column: "Колонка 6"
      },
      comments: []
    },
  ]

  return createPortal((
      <div className={classNames('contentBlock', 'shadow', styles.list)}>
        {
          tasks.map(item =>
            <TaskItem
              key={item.id}
              item={item}
            />
          )
        }
      </div>
    ), document.getElementById('modal_root')
  );
}
