import { profileAPI } from "../../API/api";

const SET_USER_CALENDAR = 'SET_USER_CALENDAR';
const CREATE_EVENT = 'CREATE_EVENT';

let initialState = {
  calendar: {},
  isFetching: false
};

export const userCalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CALENDAR:
      return {
        ...state,
        calendar: action.calendar,
      }
    case CREATE_EVENT:
      return {
        ...state,
        calendar: {
          events: [...state.calendar.events, action.event]
        },
      }
    default: return { ...state };
  }
}

const setCalendar = (calendar) => ({ type: SET_USER_CALENDAR, calendar });
const setEvent = (event) => ({ type: CREATE_EVENT, event });

export const getUserCalendar = (id) => async (dispatch) => {
  await profileAPI.getUserCalendar(id)
    .then(response => dispatch(setCalendar(response.data)))
    .catch(err => console.log(err))
}

export const createUserEvent = ({
  userId: userId,
  date: date,
  time: time,
  name: name,
  repeat: repeat,
  end_date: end_date
}) => async (dispatch) => {
  await profileAPI.createUserEvent({
    userId: userId,
    date: date,
    time: time,
    name: name,
    repeat: repeat,
    end_date: end_date
  })
    .then(response => dispatch(setEvent(response.data)))
    .catch(err => console.log(err))
}
