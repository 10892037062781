import React from 'react';

export default function CommentIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2187_807)">
        <path d="M13.3333 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 10.6667C0 11.3739 0.280952 12.0522 0.781049 12.5523C1.28115 13.0524 1.95942 13.3333 2.66667 13.3333H4.6L7.56733 15.842C7.68771 15.9439 7.8403 15.9998 7.998 15.9998C8.1557 15.9998 8.30829 15.9439 8.42867 15.842L11.4 13.3333H13.3333C14.0406 13.3333 14.7189 13.0524 15.219 12.5523C15.719 12.0522 16 11.3739 16 10.6667V2.66667C16 1.95942 15.719 1.28115 15.219 0.781049C14.7189 0.280952 14.0406 0 13.3333 0ZM14.6667 10.6667C14.6667 11.0203 14.5262 11.3594 14.2761 11.6095C14.0261 11.8595 13.687 12 13.3333 12H11.4C11.0849 12.0001 10.7799 12.1118 10.5393 12.3153L8 14.46L5.462 12.3153C5.22104 12.1115 4.9156 11.9998 4.6 12H2.66667C2.31304 12 1.97391 11.8595 1.72386 11.6095C1.47381 11.3594 1.33333 11.0203 1.33333 10.6667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H13.3333C13.687 1.33333 14.0261 1.47381 14.2761 1.72386C14.5262 1.97391 14.6667 2.31304 14.6667 2.66667V10.6667Z" fill="#3E5196" />
        <path d="M4.66667 4.66666H8C8.17681 4.66666 8.34638 4.59642 8.4714 4.4714C8.59643 4.34638 8.66667 4.17681 8.66667 3.99999C8.66667 3.82318 8.59643 3.65361 8.4714 3.52859C8.34638 3.40357 8.17681 3.33333 8 3.33333H4.66667C4.48986 3.33333 4.32029 3.40357 4.19526 3.52859C4.07024 3.65361 4 3.82318 4 3.99999C4 4.17681 4.07024 4.34638 4.19526 4.4714C4.32029 4.59642 4.48986 4.66666 4.66667 4.66666Z" fill="#3E5196" />
        <path d="M11.3333 6H4.66667C4.48986 6 4.32029 6.07024 4.19526 6.19526C4.07024 6.32029 4 6.48986 4 6.66667C4 6.84348 4.07024 7.01305 4.19526 7.13807C4.32029 7.2631 4.48986 7.33333 4.66667 7.33333H11.3333C11.5101 7.33333 11.6797 7.2631 11.8047 7.13807C11.9298 7.01305 12 6.84348 12 6.66667C12 6.48986 11.9298 6.32029 11.8047 6.19526C11.6797 6.07024 11.5101 6 11.3333 6Z" fill="#3E5196" />
        <path d="M11.3333 8.66667H4.66667C4.48986 8.66667 4.32029 8.73691 4.19526 8.86193C4.07024 8.98696 4 9.15653 4 9.33334C4 9.51015 4.07024 9.67972 4.19526 9.80474C4.32029 9.92977 4.48986 10 4.66667 10H11.3333C11.5101 10 11.6797 9.92977 11.8047 9.80474C11.9298 9.67972 12 9.51015 12 9.33334C12 9.15653 11.9298 8.98696 11.8047 8.86193C11.6797 8.73691 11.5101 8.66667 11.3333 8.66667Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2187_807">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
