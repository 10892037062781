import React from 'react';
import { useEffect } from 'react';
import { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsItem } from '../../../../redux/News/newsReducer';
import { selectNewsItem } from '../../../../redux/News/newsSelector';
import { generateKey } from '../../../../utils/generateKey';
import Preloader from '../../../common/Preloader/Preloader';
import { useParams } from "react-router-dom";

const NewsItemPage = lazy(() => import('./NewsItemPage'));

export default function NewsItemPageContainer(props) {

  let { newsId } = useParams();

  console.log(newsId)

  const dispatch = useDispatch();
  const newsItemInfo = useSelector(selectNewsItem);

  useEffect(() => {
    dispatch(getNewsItem(newsId))
  }, [dispatch])

  const more = [
    {
      id: generateKey(),
      category: {
        name: 'Фильмы'
      },
      info: {
        date: '2023-12-01',
        duration: '78 мин',
        description: '<p>Гангстеры всех мастей делят наркоферму. Закрученная экшен-комедия Гая Ричи с Мэттью Макконахи и Хью Грантом<p>',
        name: '«Плохой уикенд, мы должны это понять». Макс Ферстаппен — о Гран-при Нидерландов',
        banner: 'https://12-kanal.ru/upload/iblock/608/4.jpg',
      }
    },
    {
      id: generateKey(),
      category: {
        name: 'Фильмы'
      },
      info: {
        date: '2023-12-01',
        duration: '78 мин',
        description: '<p>Гангстеры всех мастей делят наркоферму. Закрученная экшен-комедия Гая Ричи с Мэттью Макконахи и Хью Грантом<p>',
        name: 'Игрок «Родины» Мещанинов: рад, что Тимошенко перешёл в «Акрон» и пробует свои силы в РПЛ',
        banner: 'https://12-kanal.ru/upload/iblock/608/4.jpg',
      }
    },
    {
      id: generateKey(),
      category: {
        name: 'Фильмы'
      },
      info: {
        date: '2023-12-01',
        duration: '78 мин',
        description: '<p>Гангстеры всех мастей делят наркоферму. Закрученная экшен-комедия Гая Ричи с Мэттью Макконахи и Хью Грантом<p>',
        name: 'Генменеджер «Сибири» рассказал, кем в клубе остались недовольны по итогам предсезонки',
        banner: 'https://12-kanal.ru/upload/iblock/608/4.jpg',
      }
    },
    {
      id: generateKey(),
      category: {
        name: 'Фильмы'
      },
      info: {
        date: '2023-12-01',
        duration: '78 мин',
        description: '<p>Гангстеры всех мастей делят наркоферму. Закрученная экшен-комедия Гая Ричи с Мэттью Макконахи и Хью Грантом<p>',
        name: '«Райо Вальекано» — «Барселона»: ставка Александра Мостового на игру чемпионата Испании',
        banner: 'https://12-kanal.ru/upload/iblock/608/4.jpg',
      }
    },
    {
      id: generateKey(),
      category: {
        name: 'Фильмы'
      },
      info: {
        date: '2023-12-01',
        duration: '78 мин',
        description: '<p>Гангстеры всех мастей делят наркоферму. Закрученная экшен-комедия Гая Ричи с Мэттью Макконахи и Хью Грантом<p>',
        name: 'Тут предлагаю показывать по пять доп новостей. Название ограничиваю тремя строками',
        banner: 'https://12-kanal.ru/upload/iblock/608/4.jpg',
      }
    },
  ]

  return (
    <Suspense fallback={<Preloader />}>
      <NewsItemPage
        info={newsItemInfo}
        more={more}
        newsId={newsId}
      />
    </Suspense>
  );
}
