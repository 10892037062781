import React, { useEffect, useRef, useState } from 'react';
import styles from './boardcontrols.module.css';
import classNames from 'classnames';
import AddIcon from '../../../../../../svgIcons/AddIcon';


export default function BoardControls(props) {

  const refList = useRef(null)

  const outsideHandleClick = (e) => {
    if (refList.current && !refList.current.contains(e.target)) {
      props.handleClickClose(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  const [color, setColor] = useState(props.currentColor || null)
  const [colorFromInput, setColorFromInput] = useState(props.currentColor || null)

  return (
    <div
      ref={refList}
      className={classNames('flex', 'shadow', styles.controls, (props.columnPos || props.taskPos) && styles.columnControls)}
    >
      {
        props.controlsList.filter(item => !item.hidden).map(item =>
          !item.pref ?
            <button
              key={item.id}
              className={classNames('flex', 'iconBtn', 'nowrapString', item.name === 'Вложения' && styles.resizeSVG)}
              onClick={item.key
                ?
                () => {
                  item.callBack(item.key);
                  props.handleClickClose(false);
                }
                :
                () => {
                  item.callBack();
                  props.handleClickClose(false);
                }}
              style={item.console && { background: 'red' }}
            >
              {item.icon}
              {item.name}
            </button>
            :
            <div className={styles.prefColor}>
              <p>
                {item.name}
              </p>
              {
                item.pref === 'colors' &&
                <div className={classNames('flex', styles.colorsMenu)}>
                  <div className={classNames('flex', styles.colorsWrapper)}>
                    {
                      item.colorList.map((elem, index) =>
                        <div
                          key={index}
                          className={styles.colorInputBox}
                        >
                          <input
                            type='radio'
                            onChange={(e) => {
                              item.callBack(e.target.value)
                              setColor(e.target.value)
                            }}
                            className={classNames('iconBtn', styles.colorInput)}
                            style={{ backgroundColor: `${elem.name}` }}
                            name='color-col'
                            id={elem.name + index}
                            value={elem.name}
                            checked={elem.name === color}
                          >
                          </input>
                          <label
                            className={styles.colorLabel}
                            style={{ backgroundColor: `${elem.name}` }}
                            htmlFor={elem.name + index}
                          >
                          </label>
                        </div>
                      )
                    }
                  </div>
                  <div className={classNames('flex', styles.myColor)}>
                    <div className={classNames('flex', styles.colorInputWrapper, styles.colorInputBox)}>
                      <input
                        type='color'
                        id='inputColor'
                        className={classNames('flex', 'iconBtn', styles.colorAddBtn)}
                        onChange={e => {
                          setColorFromInput(e.target.value)
                          setColor(e.target.value)
                        }}
                        onBlur={e => item.addColor(e.target.value)}
                        value={color}
                      />
                      <label htmlFor='inputColor' className={'flex'}>
                        <AddIcon />
                      </label>
                    </div>
                    {
                      colorFromInput &&
                      <div
                        style={{ backgroundColor: `${colorFromInput}` }}
                        className={classNames(styles.colorFromInput, styles.colorInputBox)}
                      >
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
        )
      }
    </div>
  )
}
