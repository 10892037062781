import { projectItemAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_FINANCE = 'SET_FINANCE';
const ADD_FINANCE_ITEM = 'ADD_FINANCE_ITEM';
const EDIT_FINANCE_ITEM = 'EDIT_FINANCE_ITEM';
const EDIT_FINANCE_STATUS = 'EDIT_FINANCE_STATUS';
const EDIT_FINANCE_FACTDATE = 'EDIT_FINANCE_FACTDATE';
const DELETE_FINANCE_ITEM = 'DELETE_FINANCE_ITEM';
const TOGGLE_FILTERED = 'TOGGLE_FILTERED'

let initialState = {
  projectFinance: [],
  isFetching: false,
  isFiltered: false,
};

export const projectFinanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINANCE:
      return {
        ...state,
        projectFinance: action.projectFinance,
      }
    case ADD_FINANCE_ITEM:
      return {
        ...state,
        projectFinance: [action.newItem, ...state.projectFinance],
      }
    case EDIT_FINANCE_ITEM:
      return {
        ...state,
        projectFinance: mapObj(state.projectFinance, action.financeId, 'id', action.newObj)
      }
    case EDIT_FINANCE_STATUS:
      return {
        ...state,
        projectFinance: mapObj(state.projectFinance, action.financeId, 'id', { status: action.field })
      }
    case EDIT_FINANCE_FACTDATE:
      return {
        ...state,
        projectFinance: mapObj(state.projectFinance, action.financeId, 'id', { fact_date: action.field })
      }
    case DELETE_FINANCE_ITEM:
      return {
        ...state,
        projectFinance: state.projectFinance.filter((el) => el.id !== action.financeId)
      }
    case TOGGLE_FILTERED: {
      return {
        ...state,
        isFiltered: action.isFiltered
      }
    }
    default: return { ...state };
  }
}

const setFinance = (projectFinance) => ({ type: SET_FINANCE, projectFinance });
const setAddFinance = (newItem) => ({ type: ADD_FINANCE_ITEM, newItem });
const setEditFinance = (financeId, newObj) => ({ type: EDIT_FINANCE_ITEM, financeId, newObj });
const setEditFinanceStatus = (financeId, field) => ({ type: EDIT_FINANCE_STATUS, financeId, field });
const setEditFinanceFactdate = (financeId, field) => ({ type: EDIT_FINANCE_FACTDATE, financeId, field });
const setDeleteFinance = (financeId) => ({ type: DELETE_FINANCE_ITEM, financeId });
const toggleFiltered = (isFiltered) => ({ type: TOGGLE_FILTERED, isFiltered });

// Finances
export const getProjectFinance = (id) => async (dispatch) => {
  await projectItemAPI.getProjectFinance(id)
    .then(response => dispatch(setFinance(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(false));
  sessionStorage.removeItem(`finansesFiltered${id}`);
  sessionStorage.removeItem(`finansesFilteredDatas${id}`)
}

export const addFinanceItem = (
  {
    project: project,
    finance_type: finance_type,
    plan_date: plan_date,
    purpose: purpose,
    amount: amount,
    comment: comment,
    files: finance_files,
    status: status,
    creator: creator
  }
) => async (dispatch) => {
  await projectItemAPI.addFinanceItem(
    {
      project: project,
      finance_type: finance_type,
      plan_date: plan_date,
      purpose: purpose,
      amount: amount,
      comment: comment,
      files: finance_files,
      status: status,
      creator: creator
    }
  )
    .then(response => dispatch(setAddFinance(response.data)))
    .catch(err => console.log(err))
}
export const editFinanceItem = (
  {
    id: id,
    project: project,
    finance_type: finance_type,
    plan_date: plan_date,
    fact_date: fact_date,
    purpose: purpose,
    amount: amount,
    comment: comment,
    files: finance_files,
    status: status,
    creator: creator
  }
) => async (dispatch) => {
  await projectItemAPI.editFinanceItem(
    {
      id: id,
      project: project,
      finance_type: finance_type,
      plan_date: plan_date,
      fact_date: fact_date,
      purpose: purpose,
      amount: amount,
      comment: comment,
      files: finance_files,
      status: status,
      creator: creator
    }
  )
    .then(response => dispatch(setEditFinance(id, response.data)))
    .catch(err => console.log(err))
}
export const editFinanceStatus = (
  {
    id: id,
    status: status,
  }
) => async (dispatch) => {
  await projectItemAPI.editFinanceStatus(
    {
      id: id,
      status: status,
    }
  )
    .then(response => dispatch(setEditFinanceStatus(id, response.data.status)))
    .catch(err => console.log(err))
}
export const editFinanceFactdate = (
  {
    id: id,
    fact_date: fact_date,
  }
) => async (dispatch) => {
  await projectItemAPI.editFinanceFactDate(
    {
      id: id,
      fact_date: fact_date,
    }
  )
    .then(response => dispatch(setEditFinanceStatus(id, response.data.fact_date)))
    .catch(err => console.log(err))
}

export const deleteFinanceItem = (id) => async (dispatch) => {
  await projectItemAPI.deleteFinanceItem(id)
    .then(() => dispatch(setDeleteFinance(id)))
}

export const getFilteredFinanses = (
  {
    projectId: projectId,
    plan_date: plan_date,
    fact_date: fact_date,
    statuses: statuses,
    finance_type: finance_type,
    min_amount: min_amount,
    max_amount: max_amount,
  }
) => async (dispatch) => {
  await projectItemAPI.filterFinance(
    {
      projectId: projectId,
      plan_date: plan_date,
      fact_date: fact_date,
      statuses: statuses,
      finance_type: finance_type,
      min_amount: min_amount,
      max_amount: max_amount,
    }
  )
    .then(response => dispatch(setFinance(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(true))
  sessionStorage.setItem(`finansesFiltered${projectId}`, true);
  sessionStorage.setItem(`finansesFilteredDatas${projectId}`,
    JSON.stringify(
      {
        plan_date: plan_date,
        fact_date: fact_date,
        statuses: statuses,
        finance_type: finance_type,
        min_amount: min_amount,
        max_amount: max_amount,
      }
    )
  );
}
