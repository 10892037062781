import React from "react";
import { createPortal } from "react-dom";
import SharePopup from "./SharePopup";

export default function SharePopupWrapper(props) {

  console.log(props.insideParent)

  return (
    !props.insideParent ?
      createPortal((
        <SharePopup
          handleClickClose={props.handleClickClose}
          popupHeader={props.popupHeader}
        />
      ), document.getElementById('modal_root'))
      :
      <SharePopup
        handleClickClose={props.handleClickClose}
        popupHeader={props.popupHeader}
      />
  )
}
