import classNames from 'classnames';
import React, { useState } from 'react';
import CheckListIcon from '../../../../../../svgIcons/CheckListIcon';
import CheckRoundIcon from '../../../../../../svgIcons/CheckRoundIcon';
import PauseIcon from '../../../../../../svgIcons/PauseIcon';
import PlayIcon from '../../../../../../svgIcons/PlayIcon';
import SettingIcon from '../../../../../../svgIcons/SettingIcon';
import { start } from '../../../../../../utils/timerFunc';
import TaskList from '../../TaskList/TaskList';
import Time from '../../Time/Time';
import styles from './timercontrols.module.css';

export default function TimerControls(props) {

  const [showList, setShowList] = useState(false);

  return (
    <div className={classNames('flex', 'iconBtnGroup', styles.controls)}>
      <Time
        time={props.time}
      />
      <div className={classNames('flex', 'iconBtnGroup')}>
        {!props.timerStatus &&
          <button
            onClick={() => props.startTimer()}
            className={classNames('flex', 'iconBtn')}
          >
            <PlayIcon />
          </button>
        }
        {props.timerStatus &&
          <button
            onClick={() => props.pauseTimer()}
            className={classNames('flex', 'iconBtn')}
          >
            <PauseIcon />
          </button>
        }
        {/* {!props.timerStatus &&
          <button
            className={classNames('flex', 'iconBtn')}
          >
            <SettingIcon />
          </button>
        } */}
        {/* {props.timerStatus &&
          <button
            className={classNames('flex', 'iconBtn')}
          >
            <CheckRoundIcon />
          </button>
        } */}
        <button
          onClick={() => setShowList(!showList)}
          className={classNames('flex', 'iconBtn')}
        >
          <CheckListIcon />
        </button>
      </div>
      {
        showList &&
        <TaskList />
      }
    </div>
  );
}
