import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './checklistpopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { generateKey } from "../../../../../../../../../../utils/generateKey";
import { redirect } from "react-router-dom";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import { useState } from "react";
import { socket } from "../../../../../../../../../../API/socket";

export default function CheckListPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  let optionsCheck = [];

  props.checklists?.map(item => {
    optionsCheck.push({ value: item.id, label: item.name })
  })

  const [isCreatedForm, setIsCreatedForm] = useState(false)

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const currentCheck = watch('checklist');

  useEffect(() => {
    setValue('name', (currentCheck && !isCreatedForm) && "Копия " + optionsCheck?.find((с) => с.value === currentCheck)?.label)
  }, [currentCheck])

  const onSubmit = (data => {
    socket.emit('AddChecklist',
      {
        "task_id": props.taskId,
        "name": data.name,
        "checklist_id": data.checklist
      })
    props.handleClickClose()
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.checkWrap)} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Добавить чек-лист
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            {
              !isCreatedForm &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Выбор чеклиста</p>
                <div
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  className={'popupInputAllView'}
                >
                  <Controller
                    control={control}
                    name='checklist'
                    render={({ field: { value, onChange } }) => (
                      <Select
                        isSearchable={false}
                        isClearable={true}
                        maxMenuHeight={180}
                        menuPlacement={'auto'}
                        placeholder='Найти чек-лист'
                        classNamePrefix="react-select"
                        className={classNames('react-select-container')}
                        options={optionsCheck}
                        value={value ? optionsCheck.find((с) => с.value === value) : ''}
                        onChange={(val) => onChange(val?.value)}
                      />
                    )}
                    rules={{ required: 'Выберите пункт' }}
                  />
                </div>
              </div>
            }
            {
              (currentCheck || isCreatedForm) &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}> {isCreatedForm ? 'Название' : 'Новое название'}</p>
                <input
                  className={classNames('input')}
                  type='text'
                  name='name'
                  placeholder='Название чек-листа'
                  {...register('name',
                    {
                      required: {
                        value: isCreatedForm,
                        message: 'Введите название'
                      },
                      minLength: {
                        value: 5,
                        message: 'Минимум 5 символов'
                      },
                    })}
                />
              </div>
            }
            {
              !isCreatedForm &&
              <div className={styles.createNewWrap}>
                <button
                  onClick={() => {
                    setIsCreatedForm(true)
                    reset()
                  }}
                  className={classNames('btn', 'btnTransparent', styles.createNewColor)}
                  type='button'
                >
                  Создать новый
                </button>
              </div>
            }
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Добавить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
