import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBoard, deleteBoard, getBoards } from '../../../../../redux/boardsListReducer';
import { selectBoards } from '../../../../../redux/boardsListSelector';
import AddIcon from '../../../../../svgIcons/AddIcon';
import HomeIcon from '../../../../../svgIcons/HomeIcon';
import { cutString } from '../../../../../utils/cutString';
import BoardLink from './BoardLink/BoardLink';
import styles from './projectheader.module.css';
import { NavLink } from 'react-router-dom';
import ArchivedBoardList from './ArchiveBoardList/ArchiveBoardList';
import ConfirmWindow from '../../../../common/ConfirmWindow/ConfirmWindow';
import { createPortal } from 'react-dom';

export default function ProjectHeader(props) {

  const refArchive = useRef(null)

  const dispatch = useDispatch();
  const boards = useSelector(selectBoards);
  const [addBoardInput, setAddBoardInput] = useState(false);
  const [nameBoard, setNameBoard] = useState('');
  const [showArchive, setShowArchive] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [boardId, setBoardId] = useState('');

  useEffect(() => {
    dispatch(getBoards(props.projectId))
  }, [dispatch, props.projectId])

  const handleClickOpenConfirm = () => {
    setIsOpenConfirmPopup(true)
    document.body.classList.add('modal-show');
  }

  const handleClickCloseConfirm = () => {
    setIsOpenConfirmPopup(false)
    document.body.classList.remove('modal-show');
  }

  const handleDeleteBoard = () => {
    dispatch(deleteBoard(boardId))
      .then(handleClickCloseConfirm)
  }

  const handleShowInput = () => {
    addBoardInput ? setAddBoardInput(false) : setAddBoardInput(true);
  }

  const handleChangeName = (e) => {
    setNameBoard(e.target.value);
  }

  const handleAddBoard = () => {
    dispatch(addBoard({
      name: nameBoard,
      project: props.projectId
    }))
      .then(() => setAddBoardInput(false))
  }

  // const boards = [
  //   {
  //     id: 1,
  //     name: "Доска 1",
  //     archived: false,
  //   },
  //   {
  //     id: 2,
  //     name: "Доска 3",
  //     archived: false,
  //   },
  //   {
  //     id: 3,
  //     name: "Доска 1",
  //     archived: false,
  //   },
  //   {
  //     id: 4,
  //     name: "Доска 1",
  //     archived: false,
  //   },
  //   {
  //     id: 5,
  //     name: "Доска 3",
  //     archived: false,
  //   },
  //   {
  //     id: 6,
  //     name: "Доска 1",
  //     archived: false,
  //   },
  //   {
  //     id: 7,
  //     name: "Доска 1",
  //     archived: true,
  //   },

  // ]

  const visibleBoards = boards.filter(item => !item.archived);
  const archivedBoards = boards.filter(item => item.archived);


  // Horizontal Scroll

  const [scrollBtns, setScrollBtns] = useState(false);

  const refLine = useRef(null);
  const refLinks = useRef(null);
  const refInput = useRef(null);
  const refBtn = useRef(null);

  const [startX, setStartX] = useState(0)

  useEffect(() => {

    const lineWidth = refLine.current?.getBoundingClientRect().width;
    const linksWidth = refLinks.current?.getBoundingClientRect().width;
    const inputWidth = refInput.current?.getBoundingClientRect().width;
    const btnWidth = refBtn.current?.getBoundingClientRect().width;
    const archiveWidth = refArchive.current?.getBoundingClientRect().width;

    setStartX(refLine.current.scrollLeft);

    let summ = btnWidth;
    if (linksWidth) summ = summ + linksWidth;
    if (inputWidth) summ = summ + inputWidth;
    if (archiveWidth) summ = summ + archiveWidth;

    if (summ > lineWidth) {
      setScrollBtns(true)
    } else {
      setScrollBtns(false)
    }

    return () => {}

  }, [props.width, addBoardInput, visibleBoards, archivedBoards])

  const handleScrollRight = () => {
    const interval = setInterval(() => { refLine.current.scrollLeft = refLine.current.scrollLeft + 1 }, 1)
    setTimeout(() => clearInterval(interval), 300)
  }
  const handleScrollLeft = () => {
    const interval = setInterval(() => { refLine.current.scrollLeft = refLine.current.scrollLeft - 1 }, 1)
    setTimeout(() => clearInterval(interval), 300)
  }

  return (
    <div
      className={classNames('flex', 'pageHeader', styles.progectHeader)}
    >
      <NavLink
        to=''
        className={classNames('flex', styles.pageTitle)}
      >
        <span className={styles.titleIcon}><HomeIcon /></span>
        {props.width > 768 &&
          cutString(props.title || 'Название проекта', 30)
        }
      </NavLink>
      <div
        className={classNames('flex', styles.boardsLine)}
        ref={refLine}
      >
        <div
          ref={refLinks}
          className={classNames('flex', styles.boardWrapper)}
        >
          {
            visibleBoards?.map((item) =>
              <BoardLink
                key={item.id}
                item={item}
              />
            )
          }
        </div>
        {
          addBoardInput &&
          <div
            ref={refInput}
            className={styles.addBoardWrapper}
          >
            <input
              autoFocus={true}
              className={classNames('input', styles.addBoardInput)}
              placeholder='Название доски'
              onChange={(e) => handleChangeName(e)}
            />
            <button
              disabled={nameBoard.length > 0 ? false : true}
              className={classNames('btn', styles.submitBoardBtn)}
              onClick={handleAddBoard}
            >
              ОК
            </button>
          </div>
        }
        <button
          ref={refBtn}
          className={classNames('flex', 'iconBtn', addBoardInput && styles.inputActiveBtb, visibleBoards?.length > 0 && styles.addBtn)}
          onClick={handleShowInput}
        >
          <AddIcon />
        </button>
        {
          archivedBoards.length > 0 &&
          <div
            className={classNames(styles.acrhBox)}
            ref={refArchive}
          >
            <button
              className={classNames('iconBtn', 'nowrapString', styles.acrhBtn)}
              onClick={() => setShowArchive(!showArchive)}
            >
              Архив досок
            </button>
            {
              showArchive &&
              createPortal((
                <div
                  style={{
                    top: `${(refArchive.current?.getBoundingClientRect().y || 0) + refArchive.current?.getBoundingClientRect().height + window.scrollY + 20}px`,
                    left: `${(refArchive.current?.getBoundingClientRect().x + refArchive.current?.getBoundingClientRect().width || 0)}px`,
                  }}
                  className={classNames('absolutePortal', styles.archivePortal)}
                >
                  <ArchivedBoardList
                    setBoardId={setBoardId}
                    archivedBoards={archivedBoards}
                    closePopup={setShowArchive}
                    handleClickOpenConfirm={handleClickOpenConfirm}
                  />
                </div>
              ), document.getElementById('modal_root'))
            }
            {isOpenConfirmPopup &&
              <ConfirmWindow
                title={'Удалить доску?'}
                onDelete={handleDeleteBoard}
                closeDelete={handleClickCloseConfirm}
              />
            }
          </div>
        }
      </div>
      {
        scrollBtns &&
        <div className={classNames('flex', styles.scrollBtns)}>
          <button
            onClick={handleScrollLeft}
            className={classNames('flex', styles.arrowBtn)}
          >
            &lt;
          </button>
          <button
            onClick={handleScrollRight}
            className={classNames('flex', styles.arrowBtn)}
          >
            &gt;
          </button>
        </div>
      }
    </div>
  );
}
