import classNames from "classnames";
import React, { useState } from "react";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import UserInfo from "../../../../../../../../../common/UserInfo/UserInfo";
import UsersPopupWrapper from "../../TaskPopups/UsersPopup/UsersPopupWrapper";
import styles from './taskcardusers.module.css';
import TaskUserWrapper from "./TaskUserWrapper";

export default function TaskCardUsers(props) {

  const [isOpenUserPopup, setIsOpenUserPopup] = useState(false);

  const handleClickOpenUser = () => {
    setIsOpenUserPopup(true);
    document.body.classList.add('modal-show');
  }

  const handleClickClosePopup = () => {
    isOpenUserPopup && setIsOpenUserPopup(false);
    document.body.classList.remove('modal-show');
  }

  return (
    <div className={styles.taskUsers}>
      <div className={classNames('flex', 'blockTitleWrapper', styles.userTitleWrap)}>
        <h2>Участники</h2>
        {
          !props.readOnly &&
          <button
            onClick={handleClickOpenUser}
            className={classNames('flex', 'iconBtn')}
          >
            <AddIcon />
          </button>
        }
      </div>
      <div className={classNames('flex', styles.users)}>
        {
          props.users.length > 0 ?
            props.users.map(item =>
              <TaskUserWrapper
                key={item.user?.id}
                avatar={item.user?.avatar}
                first_name={item.user?.first_name}
                last_name={item.user?.last_name}
                email={item.position?.name}
                userId={item.id}
                taskId={props.taskId}
              />
            )
            :
            <p>Участники не назначены</p>
        }
      </div>
      {
        isOpenUserPopup &&
        <UsersPopupWrapper
          insideParent={true}
          project={props.project}
          taskId={props.taskId}
          users={props.users}
          handleClickClose={handleClickClosePopup}
        />
      }
    </div >
  )
}
