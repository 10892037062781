export function editName(string) {
  if (string) {
    return `${string?.slice(0, 1)}.`
  } else {
    return ''
  }
}

export function editNameWithoutDots(string) {
  return `${string?.slice(0, 1)}`
}
