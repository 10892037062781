import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLabels } from "../../../../../../../../../../redux/BoardPage/сolumnSelector";
import { getLabels } from "../../../../../../../../../../redux/BoardPage/сolumnsReducer";
import TagPopup from "./TagPopup";

export default function TagPopupWrapper(props) {

  const dispatch = useDispatch();
  const allTagsList = useSelector(selectLabels)

  useEffect(() => {
    dispatch(getLabels(props.boardId))
  }, [])

  return (
    !props.insideParent ?
      createPortal((
        <TagPopup
          taskId={props.taskId}
          allTagsList={allTagsList}
          handleClickClose={props.handleClickClose}
        />
      ), document.getElementById('modal_root'))
      :
      <TagPopup
        taskId={props.taskId}
        allTagsList={allTagsList}
        handleClickClose={props.handleClickClose}
      />
  )
}
