import React from 'react';

export default function NewsIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2013_68)">
        <path d="M15.8333 0H8.33333C6.03583 0 4.16667 1.86917 4.16667 4.16667V5H3.75C1.6825 5 0 6.6825 0 8.75V17.0833C0 18.6908 1.3075 19.9992 2.91583 20H15.8333C18.1308 20 20 18.1308 20 15.8333V4.16667C20 1.86917 18.1308 0 15.8333 0ZM4.16667 17.0833C4.16667 17.7725 3.60583 18.3333 2.91667 18.3333C2.2275 18.3333 1.66667 17.7725 1.66667 17.0833V8.75C1.66667 7.60167 2.60167 6.66667 3.75 6.66667H4.16667V17.0833ZM18.3333 15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333H5.55167C5.73167 17.9542 5.83333 17.5308 5.83333 17.0833V4.16667C5.83333 2.78833 6.955 1.66667 8.33333 1.66667H15.8333C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V15.8333ZM16.6667 5.83333C16.6667 6.29333 16.2933 6.66667 15.8333 6.66667H13.3333C12.8733 6.66667 12.5 6.29333 12.5 5.83333C12.5 5.37333 12.8733 5 13.3333 5H15.8333C16.2933 5 16.6667 5.37333 16.6667 5.83333ZM16.6667 9.16667C16.6667 9.62667 16.2933 10 15.8333 10H8.33333C7.87333 10 7.5 9.62667 7.5 9.16667C7.5 8.70667 7.87333 8.33333 8.33333 8.33333H15.8333C16.2933 8.33333 16.6667 8.70667 16.6667 9.16667ZM16.6667 12.5C16.6667 12.96 16.2933 13.3333 15.8333 13.3333H8.33333C7.87333 13.3333 7.5 12.96 7.5 12.5C7.5 12.04 7.87333 11.6667 8.33333 11.6667H15.8333C16.2933 11.6667 16.6667 12.04 16.6667 12.5ZM16.6667 15.8333C16.6667 16.2933 16.2933 16.6667 15.8333 16.6667H8.33333C7.87333 16.6667 7.5 16.2933 7.5 15.8333C7.5 15.3733 7.87333 15 8.33333 15H15.8333C16.2933 15 16.6667 15.3733 16.6667 15.8333ZM7.5 5.83333V4.16667C7.5 3.70667 7.87333 3.33333 8.33333 3.33333H10C10.46 3.33333 10.8333 3.70667 10.8333 4.16667V5.83333C10.8333 6.29333 10.46 6.66667 10 6.66667H8.33333C7.87333 6.66667 7.5 6.29333 7.5 5.83333Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2013_68">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
 