import React from 'react';

export default function LockIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4801 9.616V8.66667C16.4801 7.42899 16.0081 6.242 15.1679 5.36684C14.3278 4.49167 13.1883 4 12.0001 4C10.8119 4 9.67242 4.49167 8.83226 5.36684C7.9921 6.242 7.5201 7.42899 7.5201 8.66667V9.616C6.95009 9.87514 6.46493 10.3017 6.12394 10.8435C5.78295 11.3853 5.60092 12.0188 5.6001 12.6667V16.6667C5.60111 17.5504 5.93858 18.3976 6.53848 19.0225C7.13837 19.6474 7.95172 19.9989 8.8001 20H15.2001C16.0485 19.9989 16.8618 19.6474 17.4617 19.0225C18.0616 18.3976 18.3991 17.5504 18.4001 16.6667V12.6667C18.3993 12.0188 18.2172 11.3853 17.8763 10.8435C17.5353 10.3017 17.0501 9.87514 16.4801 9.616ZM8.8001 8.66667C8.8001 7.78261 9.13724 6.93477 9.73736 6.30964C10.3375 5.68452 11.1514 5.33333 12.0001 5.33333C12.8488 5.33333 13.6627 5.68452 14.2628 6.30964C14.863 6.93477 15.2001 7.78261 15.2001 8.66667V9.33333H8.8001V8.66667ZM17.1201 16.6667C17.1201 17.1971 16.9178 17.7058 16.5577 18.0809C16.1977 18.456 15.7093 18.6667 15.2001 18.6667H8.8001C8.29088 18.6667 7.80252 18.456 7.44245 18.0809C7.08238 17.7058 6.8801 17.1971 6.8801 16.6667V12.6667C6.8801 12.1362 7.08238 11.6275 7.44245 11.2525C7.80252 10.8774 8.29088 10.6667 8.8001 10.6667H15.2001C15.7093 10.6667 16.1977 10.8774 16.5577 11.2525C16.9178 11.6275 17.1201 12.1362 17.1201 12.6667V16.6667Z" fill="#3E5196" />
      <path d="M12 13.6C11.7878 13.6 11.5843 13.6632 11.4343 13.7757C11.2842 13.8883 11.2 14.0409 11.2 14.2V15.4C11.2 15.5591 11.2842 15.7117 11.4343 15.8243C11.5843 15.9368 11.7878 16 12 16C12.2121 16 12.4156 15.9368 12.5656 15.8243C12.7157 15.7117 12.8 15.5591 12.8 15.4V14.2C12.8 14.0409 12.7157 13.8883 12.5656 13.7757C12.4156 13.6632 12.2121 13.6 12 13.6Z" fill="#3E5196" />
    </svg>
  );
}
