import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './checkitempopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { generateKey } from "../../../../../../../../../../utils/generateKey";
import { redirect } from "react-router-dom";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import { useState } from "react";

export default function CheckItemPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    props.handleAddChecklistItem(data)
    props.handleClickClose()
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.checkWrap)} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Добавить пункт в чек-лист
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Название</p>
              <input
                className={classNames('input')}
                type='text'
                name='name'
                placeholder='Название'
                {...register('name',
                  {
                    required: 'Введите название',
                    minLength: {
                      value: 3,
                      message: 'Минимум 3 символа'
                    },
                  })}
              />
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Добавить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
