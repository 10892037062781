import { projectItemAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_COMMENTS = 'SET_COMMENTS';
const ADD_COMMENT = 'ADD_COMMENT';
const EDIT_COMMENT = 'EDIT_COMMENT_TEXT';
const EDIT_COMMENT_PINNED = 'EDIT_COMMENT_PINNED';
const DELETE_COMMENT_ITEM = 'DELETE_COMMENT_ITEM';
const TOGGLE_FILTERED = 'TOGGLE_FILTERED'

let initialState = {
  projectComments: [],
  isFetching: false,
  isFiltered: false || JSON.parse(sessionStorage.getItem('commentsFiltered')),
};

export const projectCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS:
      return {
        ...state,
        projectComments: action.projectComments,
      }
    case ADD_COMMENT:
      return {
        ...state,
        projectComments: [...state.projectComments, action.newItem],
      }
    case EDIT_COMMENT:
      return {
        ...state,
        projectComments: mapObj(state.projectComments, action.commentId, 'id', action.newObj)
      }
    case EDIT_COMMENT_PINNED:
      return {
        ...state,
        projectComments: mapObj(state.projectComments, action.commentId, 'id', { pinned: action.field })
      }
    case DELETE_COMMENT_ITEM:
      return {
        ...state,
        projectComments: state.projectComments.filter((el) => el.id !== action.commentId)
      }
    case TOGGLE_FILTERED: {
      return {
        ...state,
        isFiltered: action.isFiltered
      }
    }
    default: return { ...state };
  }
}

const setComments = (projectComments) => ({ type: SET_COMMENTS, projectComments });
const setAddComment = (newItem) => ({ type: ADD_COMMENT, newItem });
const setEditComment = (commentId, newObj) => ({ type: EDIT_COMMENT, commentId, newObj });
const setEditCommentPinned = (commentId, field) => ({ type: EDIT_COMMENT_PINNED, commentId, field });
const setDeleteComment = (commentId) => ({ type: DELETE_COMMENT_ITEM, commentId });
const toggleFiltered = (isFiltered) => ({ type: TOGGLE_FILTERED, isFiltered });

// Comments
export const getProjectComments = (id) => async (dispatch) => {
  await projectItemAPI.getProjectComments(id)
    .then(response => dispatch(setComments(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(false));
  sessionStorage.removeItem(`commentsFiltered${id}`);
  sessionStorage.removeItem(`commentsFilteredDatas${id}`);
}

export const addComment = (
  {
    project: project,
    author: author,
    text: text,
    display_to_customer: display_to_customer,
    files: files,
  }
) => async (dispatch) => {
  await projectItemAPI.addComment(
    {
      project: project,
      author: author,
      text: text,
      display_to_customer: display_to_customer,
      files: files,
    }
  )
    .then(response => dispatch(setAddComment(response.data)))
    .catch(err => console.log(err))
}

export const editComment = (
  {
    id: id,
    project: project,
    author: author,
    text: text,
    display_to_customer: display_to_customer,
    files: files,
  }
) => async (dispatch) => {
  await projectItemAPI.editComment(
    {
      id: id,
      project: project,
      author: author,
      text: text,
      display_to_customer: display_to_customer,
      files: files,
    }
  )
    .then(response => dispatch(setEditComment(id, response.data)))
    .catch(err => console.log(err))
}
export const editCommentPinned = (
  {
    id: id,
    pinned: pinned,
  }
) => async (dispatch) => {
  await projectItemAPI.editCommentPinned(
    {
      id: id,
      pinned: pinned,
    }
  )
    .then(() => dispatch(setEditCommentPinned(id, pinned)))
    .catch(err => console.log(err))
}

export const deleteCommentItem = (id) => async (dispatch) => {
  await projectItemAPI.deleteCommentItem(id)
    .then(() => dispatch(setDeleteComment(id)))
}

export const getProjectFilteredComments = (
  {
    projectId: projectId,
    date: date,
    for_all: for_all,
    hidden: hidden,
    personal: personal,
  }
) => async (dispatch) => {
  await projectItemAPI.filterComments(
    {
      projectId: projectId,
      date: date,
      for_all: for_all,
      hidden: hidden,
      personal: personal,
    }
  )
    .then(response => dispatch(setComments(response.data)))
    .catch(err => console.log(err))

  dispatch(toggleFiltered(true));
  sessionStorage.setItem(`commentsFiltered${projectId}`, true);
  sessionStorage.setItem(`commentsFilteredDatas${projectId}`,
    JSON.stringify({
      date: date,
      for_all: for_all,
      hidden: hidden,
      personal: personal,
    })
  );
}
