import React, { useState } from 'react';
import styles from '../fileslist.module.css';
import classNames from 'classnames';
import { editFileName, editLongFileName } from '../../../../utils/fileNameEditor';
import FileIcon from '../../../../svgIcons/FileIcon';
import FilesControls from '../FilesControls/FilesControls';

export default function File(props) {

  const [showContols, setShowControls] = useState()

  return (
    <div
      className={styles.fileItemWrapper}
      onMouseEnter={() => {
        !props.readOnly && setShowControls(true)
      }}
      onMouseLeave={() => {
        !props.readOnly && setShowControls(false)
      }}
    >
      {
        props.external ?
          <div
            className={classNames('flex', styles.file)}
          >
            <a
              href={props.item.external_url}
              target="_blank"
              rel="noreferrer"
              className={classNames('flex', styles.fileLink)}>
              {!props.editFile && <FileIcon />} {editLongFileName(props.item.name)}
            </a>
            {
              props.editFile &&
              <button
                onClick={() => props.handleDeleteFile(props.filesList, props.item)}
                type="button"
                className={classNames('flex', styles.deleteFileBtn)}
              >
                <span className={classNames(styles.horLine, styles.line)}></span>
                <span className={classNames(styles.verLine, styles.line)}></span>
              </button>
            }
          </div>
          : props.doc ?
            <div
              className={classNames('flex', styles.file)}
            >
              <a
                href={props.item.file}
                target="_blank"
                download
                rel="noreferrer"
                className={classNames('flex', styles.fileLink)}>
                {!props.editFile && <FileIcon />} {editLongFileName(props.item.name)}
              </a>
              {
                props.editFile &&
                <button
                  onClick={() => props.handleDeleteFile(props.filesList, props.item)}
                  type="button"
                  className={classNames('flex', styles.deleteFileBtn)}
                >
                  <span className={classNames(styles.horLine, styles.line)}></span>
                  <span className={classNames(styles.verLine, styles.line)}></span>
                </button>
              }
            </div>
            :
            <div
              className={classNames('flex', 'imageBox', styles.image, props.editFile ? styles.editImg : styles.openImg)}
              onClick={() => {
                !props.editFile && props.setLink(props.item.file)
                !props.editFile && props.handleClickOpenImg()
              }}
            >
              <img
                src={props.item.file}
              />
              {
                props.editFile &&
                <button
                  onClick={() => props.handleDeleteFile(props.filesList, props.item)}
                  type="button"
                  className={classNames('flex', styles.deleteFileBtn)}
                >
                  <span className={classNames(styles.horLine, styles.line)}></span>
                  <span className={classNames(styles.verLine, styles.line)}></span>
                </button>
              }
            </div>
      }
      {showContols
        &&
        <FilesControls
          item={props.item}
          image={props.image}
          handleDeleteFile={props.handleDeleteFile}
          handleBanner={props.handleBanner}
        />
      }
    </div>
  );
}
