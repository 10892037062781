import React from 'react';

export default function ShareIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2044_245)">
        <path d="M18.15 14.25C18.15 16.32 16.47 18 14.4 18H3.75C1.68 18 0 16.32 0 14.25V3.59998C0 1.52998 1.68 -0.150024 3.75 -0.150024H4.5C4.9125 -0.150024 5.25 0.187476 5.25 0.599976C5.25 1.01248 4.9125 1.34998 4.5 1.34998H3.75C2.5125 1.34998 1.5 2.36248 1.5 3.59998V14.25C1.5 15.4875 2.5125 16.5 3.75 16.5H14.4C15.6375 16.5 16.65 15.4875 16.65 14.25C16.65 13.8375 16.9875 13.5 17.4 13.5C17.8125 13.5 18.15 13.8375 18.15 14.25ZM17.3475 4.41748L13.29 0.224975C13.005 -0.0750245 12.525 -0.0825243 12.2325 0.209976C11.94 0.502476 11.925 0.974976 12.2175 1.26748L16.0725 5.24248H9.75C7.68 5.24248 6 6.92248 6 8.99248V12.7425C6 13.155 6.3375 13.4925 6.75 13.4925C7.1625 13.4925 7.5 13.155 7.5 12.7425V8.99248C7.5 7.75498 8.5125 6.74248 9.75 6.74248H16.065L12.21 10.7175C11.925 11.0175 11.925 11.49 12.225 11.775C12.3675 11.9175 12.5625 11.985 12.75 11.985C12.9375 11.985 13.14 11.91 13.29 11.76L17.34 7.58248C18.2175 6.70498 18.2175 5.27998 17.3475 4.40998V4.41748Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2044_245">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
