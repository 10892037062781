// let interval;
// export const time = {
//   seconds: 0,
//   minutes: 0,
//   hours: 0
// }

// export function setValues(sec, min, hou) {
//   time.seconds = sec;
//   time.minutes = min;
//   time.hours = hou;
// }

// export function viewTime(num) {
//   if (num < 10) {
//     return '0' + num
//   } else {
//     return num
//   }
// }

// function updateTime() {
//   time.seconds++;
//   if (time.seconds === 60) {
//     time.minutes++;
//     time.seconds = 0;
//   }
//   if (time.minutes === 60) {
//     time.hours++;
//     time.minutes = 0;
//   }
// }

// export function start() {
//   interval = setInterval(updateTime, 1000);
// };

// export function pauseBtn() {
//   clearInterval(interval);
// };

// function resetBtn() {
//   clearInterval(interval);
//   time.seconds = 0;
//   time.minutes = 0;
//   time.hours = 0;
// };

export function viewTime(num) {
  if (num < 10) {
    return '0' + num
  } else {
    return num
  }
}
