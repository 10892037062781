import React, { useState } from 'react';
import styles from './navigation.module.css';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import CompanyIcon from '../../../../svgIcons/CompanyIcon';
import NewsIcon from '../../../../svgIcons/NewsIcon';
import SupportIcon from '../../../../svgIcons/SupportIcon';
import OpenMenuIcon from '../../../../svgIcons/OpenMenuIcon';
import { cutString } from '../../../../utils/cutString';
import WalletIcon from '../../../../svgIcons/WalletIcon';
import SettingIcon from '../../../../svgIcons/SettingIcon';

export default function Navigation(props) {

  const [showPopup, setShowPopup] = useState(false);

  const navItems = [
    {
      element:
        <NavLink to={`/company/${props.me?.active_organization?.id}`}
          className={classNames('flex', styles.navLink, props.isFull && styles.fullNav)}
        >
          {
            props.width <= 768
              ?
              (
                props.isFull &&
                <span className={classNames('icon', styles.navIcon)}>
                  <CompanyIcon />
                </span>
              )
              :
              <span className={classNames('icon', styles.navIcon)}>
                <CompanyIcon />
              </span>
          }
          <span className={classNames(styles.navTitle, props.isFull && styles.fullNav)}>
            Компания
          </span>
        </NavLink>,
      key: 'company',
      list: props.projects
    },
    {
      element:
        <NavLink to='/news'
          className={classNames('flex', styles.navLink, props.isFull && styles.fullNav)}
        >
          {
            props.width <= 768
              ?
              (
                props.isFull &&
                <span className={classNames('icon', styles.navIcon)}>
                  <NewsIcon />
                </span>
              )
              :
              <span className={classNames('icon', styles.navIcon)}>
                <NewsIcon />
              </span>
          }
          <span className={classNames(styles.navTitle, props.isFull && styles.fullNav)}>
            Новости
          </span>
        </NavLink>,
      key: 'news',
      list: ''
    },
    {
      element:
        <a href="https://t.me/boom_track_support"
          className={classNames('flex', styles.navLink, props.isFull && styles.fullNav)}
          target="_blank"
        >
          {
            props.width <= 768
              ?
              (
                props.isFull &&
                <span className={classNames('icon', styles.navIcon)}>
                  <SupportIcon />
                </span>
              )
              :
              <span className={classNames('icon', styles.navIcon)}>
                <SupportIcon />
              </span>
          }
          <span span className={classNames(styles.navTitle, props.isFull && styles.fullNav, 'nowrapString')} >
            Тех. поддержка
          </span >
        </a >,
      key: 'support',
      list: ''
    },
    // {
    //   element:
    //     <NavLink to='/pay'
    //       className={classNames('flex', styles.navLink, props.isFull && styles.fullNav)}
    //     >
    //       {
    //         props.width <= 768
    //           ?
    //           (
    //             props.isFull &&
    //             <span className={classNames('icon', styles.navIcon)}>
    //               <WalletIcon />
    //             </span>
    //           )
    //           :
    //           <span className={classNames('icon', styles.navIcon)}>
    //             <WalletIcon />
    //           </span>
    //       }
    //       <span span className={classNames(styles.navTitle, props.isFull && styles.fullNav)} >
    //         Оплата
    //       </span >
    //     </NavLink >,
    //   key: 'pay',
    //   list: ''
    // },
    // {
    //   element:
    //     <NavLink to='/dash'
    //       className={classNames('flex', styles.navLink, props.isFull && styles.fullNav)}
    //     >
    //       {
    //         props.width <= 768
    //           ?
    //           (
    //             props.isFull &&
    //             <span className={classNames('icon', styles.navIcon)}>
    //               <SettingIcon />
    //             </span>
    //           )
    //           :
    //           <span className={classNames('icon', styles.navIcon)}>
    //             <SettingIcon />
    //           </span>
    //       }
    //       <span span className={classNames(styles.navTitle, props.isFull && styles.fullNav)} >
    //         Дашборд
    //       </span >
    //     </NavLink >,
    //   key: 'dash',
    //   list: ''
    // },
  ]

  return (
    <div>
      <div className={classNames('flex', styles.navigation, props.isFull && styles.fullNav)}>
        {
          navItems.map((item) =>
            <div
              key={item.key}
              className={classNames(styles.navItem, !props.isFull && styles.notFullItem)}
              onMouseEnter={() => (item.key === 'company' && !props.isFull) && setShowPopup(true)}
              onMouseLeave={() => (item.key === 'company' && !props.isFull) && setShowPopup(false)}
            >
              {item.element}
              {
                (item.list && item.list?.length) ?
                  <div className={classNames('flex', styles.projectsList, props.isFull && styles.fullNav, item.list?.length > 5 && styles.scrollList)}>
                    {item.list.map((pr) =>
                      <NavLink
                        to={`/projects/${pr.id}`}
                        key={pr.id}
                        className={({ isActive }) => isActive ? classNames(styles.active, styles.projectLink) : styles.projectLink}
                      >
                        {cutString(pr.name, 15)}
                      </NavLink>
                    )}
                  </div>
                  : ''
              }
              {
                (item.key === 'company' && showPopup && item.list?.length > 0) &&
                <div
                  className={classNames('flex', 'shadow', styles.projectsPopup)}
                >
                  {item.list.map((pr) =>
                    <NavLink
                      to={`/projects/${pr.id}`}
                      key={pr.id}
                      className={({ isActive }) => isActive ? classNames(styles.active, styles.projectLink) : styles.projectLink}
                    >
                      {cutString(pr.name, 15)}
                    </NavLink>
                  )}
                </div>
              }
            </div>
          )
        }
        <button
          style={{ left: `${props.menuWidth - 30}px` }}
          className={classNames('iconBtn', 'flex', styles.openMenuBtn, props.isFull && styles.opened)}
          onClick={props.handleOpen}
        >
          <span>
            <OpenMenuIcon />
          </span>
        </button>
      </div>
    </div>
  );
}
