import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './columnsortpopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CloseIcon from "../../../../../../../../svgIcons/CloseIcon";

export default function ColumnSortPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  const dispatch = useDispatch()

  let optionsSort = [
    {
      value: `1`,
      label: 'По дате создания'
    },
    {
      value: `2`,
      label: 'По дедлайну'
    },
    {
      value: `3`,
      label: 'По названию'
    },
  ];

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    alert(`Сортируем ${data.status}`);
    props.handleClickClose();
  })

  return (createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Сортировать
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Сортировать</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='status'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsSort}
                      value={value ? optionsSort.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Применить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >), document.getElementById('modal_root'))
  )
}
