import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '../../../svgIcons/CloseIcon';
import styles from './offerpopup.module.css';

export default function OfferPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', outsideHandleClick);
    return () => { }
  }, [])

  return createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper', styles.frameWrap)}>
        <div className={classNames('popupWindow', 'shadow', styles.framePopup)} ref={ref}>
          <script src="https://forms.yandex.ru/_static/embed.js"></script>
          <iframe
            src="https://forms.yandex.ru/u/662a245c5056909517d81e19/?iframe=1"
            frameborder="0"
            name="ya-form-662a245c5056909517d81e19"
            className={styles.frame}
          // scrolling="no"
          >
          </iframe>
          <button
            onClick={() => props.handleClickClose()}
            className={classNames('iconBtn', styles.clsBtn)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  ), document.getElementById('modal_root'))
}
