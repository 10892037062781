import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ArrowIcon from '../../../../svgIcons/ArrowIcon';
import CollapseTimer from './CollapseTimer/CollapseTimer';
import FullTimer from './FullTimer/FullTimer';
import styles from './timer.module.css';

export default function Timer(props) {

  const [time, setTime] = useState({ h: 0, m: 0, s: 0 });
  const [interv, setInterv] = useState();
  const [timerStatus, setTimerStatus] = useState(false);

  let secondsUpdate = time.s;
  let minutesUpdate = time.m;
  let hoursUpdate = time.h;

  const updateTime = () => {
    secondsUpdate++;
    if (secondsUpdate === 60) {
      minutesUpdate++;
      secondsUpdate = 0;
    }
    if (minutesUpdate === 60) {
      hoursUpdate++;
      minutesUpdate = 0;
    }
    return setTime({ h: hoursUpdate, m: minutesUpdate, s: secondsUpdate })
  }

  const start = () => {
    setInterv(setInterval(updateTime, 1000));
    setTimerStatus(true)
  };

  const pause = () => {
    clearInterval(interv);
    setTimerStatus(false);
  };

  const resetBtn = () => {
    clearInterval(interv);
    setTime({ h: 0, m: 0, s: 0 })
  };

  const [isFull, setIsFull] = useState(false)

  return (
    <div className={classNames('shadow', styles.timer, isFull && styles.fullBorder)}>
      {
        !isFull &&
        <CollapseTimer
          showFull={setIsFull}
          time={time}
        />
      }
      {
        isFull &&
        <FullTimer
          timerStatus={timerStatus}
          startTimer={start}
          pauseTimer={pause}
          time={time}
        />
      }
      {
        isFull &&
        <button
          onClick={() => setIsFull(false)}
          className={classNames('flex', 'iconBtn', styles.hide)}
        >
          <ArrowIcon />
        </button>
      }
    </div>
  );
}
