import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import styles from './welcome.module.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import classNames from "classnames";
import DatePick from '../../../common/DatePick/DatePick';
import { useState } from 'react';
import { changeMe } from '../../../../redux/authReducer';
import { useNavigate } from 'react-router-dom';

export default function Welcome(props) {

  const me = useSelector(selectMe);
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    clearErrors,
    setError,
    getValues,
    setValue,
    unregister,
    register,
    control,
    watch,
    resetField,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    dispatch(changeMe({
      meId: me.user.id,
      firstName: data.name,
      lastName: data.surname,
      fatherName: data.father_name,
      // birthday: editDateForInput(data.birthday).includes(NaN) ? null : editDateForInput(data.birthday),
      avatar: data.avatar[0]
    }))
      .then(() => navigate(`/company/${me?.active_organization?.id}`))
  })

  return (

    <div className={styles.welcome}>
      <h1>Добро пожаловать в BOOM-TRACK!</h1>
      <h2>Вас пригласили в компанию {me?.active_organization?.org_name}</h2>
      <p className={styles.formPreview}>Для дальнешйей работы, пожалуйста, заполните данные ниже <span>(<span className={styles.red}>*</span> - обязательные поля)</span></p>
      <form
        className={classNames('flex', 'popupform', styles.welcomeForm)}
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          clearErrors('serverError');
        }
        }
      >
        <div className={styles.formFields}>
          <div className={classNames('flex', styles.formPart, styles.avatarBox)}>
            <span>Выберите аватар</span>
            <input
              {...register('avatar')}
              className={classNames(styles.fileInput)}
              type='file'
              accept="image/*"
              name='avatar'
              onChange={(e) => {
                e.target?.files?.length > 0 ? setAvatar(e.target.files[0]) : setAvatar(null)
              }}
            />
            {
              avatar &&
              <div className={classNames('imageBox', styles.avatarPreview)}>
                <img src={URL.createObjectURL(avatar)} alt="" />
              </div>
            }
          </div>

          <div className={styles.formPart}>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Фамилия<span className={styles.red}>*</span></p>
              <div className={'inputWrapper'}>
                <input
                  {...register('surname',
                    {
                      required: 'Введите фамилию',
                      minLength: {
                        value: 2,
                        message: 'Минимум 2 символа'
                      },
                    })
                  }
                  className={classNames('input', styles.welcomeInput, errors.surname && 'inputError')}
                  type='text'
                  name='surname'
                  placeholder='Фамилия'
                />
                {errors.surname && <div className={'errorMessage'}>{errors.surname.message}</div>}
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Имя<span className={styles.red}>*</span></p>
              <div className={'inputWrapper'}>
                <input
                  {...register('name',
                    {
                      required: 'Введите имя',
                      minLength: {
                        value: 2,
                        message: 'Минимум 2 символа'
                      },
                    })
                  }
                  className={classNames('input', styles.welcomeInput, errors.name && 'inputError')}
                  type='text'
                  name='name'
                  placeholder='Имя'
                />
                {errors.name && <div className={'errorMessage'}>{errors.name.message}</div>}
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Отчество</p>
              <div className={'inputWrapper'}>
                <input
                  {...register('father_name',
                    {
                      minLength: {
                        value: 5,
                        message: 'Минимум 5 символов'
                      },
                    })
                  }
                  className={classNames('input', styles.welcomeInput, errors.father_name && 'inputError')}
                  type='text'
                  name='father_name'
                  placeholder='Отчество'
                />
                {errors.father_name && <div className={'errorMessage'}>{errors.father_name.message}</div>}
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Дата рождения</p>
              <div className={'inputWrapper'}>
                <Controller
                  control={control}
                  name='birthday'
                  render={({ field }) => (
                    <DatePick
                      handlePickDate={field.onChange}
                      startDate={null}
                      error={errors.birthday}
                      welcome={true}
                    />
                  )}
                />
                {errors.birthday && <div className={'errorMessage'}>{errors.birthday.message}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('popupBtnsWrapper')}>
          <button
            className={'btn'}
            type='submit'
            disabled={!isValid}
          >
            Начать работу
          </button>
        </div>
      </form>
    </div>
  );
}
