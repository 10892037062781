import React from 'react';

export default function FromArchiveIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3434_623)">
        <path d="M9 0C6.78413 0.00157978 4.6471 0.822467 3 2.30475V0.75C3 0.551088 2.92098 0.360322 2.78033 0.21967C2.63968 0.0790176 2.44891 0 2.25 0C2.05109 0 1.86032 0.0790176 1.71967 0.21967C1.57902 0.360322 1.5 0.551088 1.5 0.75V3C1.5 3.59674 1.73705 4.16903 2.15901 4.59099C2.58097 5.01295 3.15326 5.25 3.75 5.25H6C6.19891 5.25 6.38968 5.17098 6.53033 5.03033C6.67098 4.88968 6.75 4.69891 6.75 4.5C6.75 4.30109 6.67098 4.11032 6.53033 3.96967C6.38968 3.82902 6.19891 3.75 6 3.75H3.75C3.72471 3.74625 3.69966 3.74099 3.675 3.73425C4.89714 2.50337 6.5086 1.73481 8.2343 1.55975C9.96 1.38469 11.693 1.81399 13.1374 2.77437C14.5818 3.73475 15.6482 5.16667 16.1544 6.82571C16.6606 8.48476 16.5754 10.2681 15.9132 11.8713C15.251 13.4744 14.0529 14.7981 12.5235 15.6163C10.994 16.4345 9.22799 16.6965 7.52686 16.3577C5.82573 16.0188 4.29494 15.1 3.19579 13.7581C2.09664 12.4163 1.49728 10.7346 1.5 9C1.5 8.80109 1.42098 8.61032 1.28033 8.46967C1.13968 8.32902 0.948912 8.25 0.75 8.25C0.551088 8.25 0.360322 8.32902 0.21967 8.46967C0.0790176 8.61032 0 8.80109 0 9C0 10.78 0.527841 12.5201 1.51677 14.0001C2.50571 15.4802 3.91131 16.6337 5.55585 17.3149C7.20038 17.9961 9.00998 18.1743 10.7558 17.8271C12.5016 17.4798 14.1053 16.6226 15.364 15.364C16.6226 14.1053 17.4798 12.5016 17.8271 10.7558C18.1743 9.00998 17.9961 7.20038 17.3149 5.55585C16.6337 3.91131 15.4802 2.50571 14.0001 1.51677C12.5201 0.527841 10.78 0 9 0Z" fill="#3E5196" />
        <path d="M9 4.5C8.80109 4.5 8.61032 4.57902 8.46967 4.71967C8.32902 4.86032 8.25 5.05109 8.25 5.25V9C8.25004 9.1989 8.32909 9.38963 8.46975 9.53025L10.7198 11.7802C10.8612 11.9169 11.0507 11.9925 11.2473 11.9908C11.4439 11.989 11.6321 11.9102 11.7711 11.7711C11.9102 11.6321 11.989 11.4439 11.9908 11.2473C11.9925 11.0507 11.9169 10.8612 11.7802 10.7198L9.75 8.6895V5.25C9.75 5.05109 9.67098 4.86032 9.53033 4.71967C9.38968 4.57902 9.19891 4.5 9 4.5Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_3434_623">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
