import classNames from 'classnames';
import React from 'react';
import { viewTime } from '../../../../../utils/timerFunc';
import styles from './time.module.css';

export default function Time(props) {

  return (
    <div className={styles.time}>
      {viewTime(props.time.h)}:{viewTime(props.time.m)}:{viewTime(props.time.s)}
    </div>
  );
}
