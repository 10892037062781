import classNames from 'classnames';
import React, { lazy, Suspense, useState } from 'react';
import styles from './userpage.module.css';
import { generateKey } from '../../../../utils/generateKey';
import Preloader from '../../../common/Preloader/Preloader';
import { useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const UserContent = lazy(() => import('./UserContent/UserContent'));
const UserHeader = lazy(() => import('./UserHeader/UserHeader'));

export default function UserPage(props) {

  let { userId } = useParams();

  const defaultUser = {
    info: {
      id: generateKey(),
      first_name: "Вячеслав",
      last_name: "Бутусов",
      father_name: "Геннадьвич",
      avatar_color: "#E41E5b",
      avatar: null,
      background_image: null,
      birthday: '2000-10-10',
      position: "Разработчик",
      phone: '+7989875465',
      mail: "pochtaRossii@pr.ru",
      company: {
        id: generateKey(),
        name: "Компания номер 1"
      },
      job: {
        month_salary: "100000",
        month_hours: "160",
        hour_salary: "625"
      }
    },
    stats: {
      tasks: "150",
      hours: "130 из 130",
      taskMoney: "6800",
      debt: "3500"
    },
    calendar: [
      {
        id: generateKey(),
        name: "Выплата ЗП",
        date: "03-10-2015"
      },
      {
        id: generateKey(),
        name: "Рождение ребенка",
        date: "01-10-2015"
      },
      {
        id: generateKey(),
        name: "Окончание испытательного срока",
        date: "07-10-2017"
      },
    ],
    comments: [
      {
        id: generateKey(),
        author: {
          "id": generateKey(),
          "first_name": "Федька",
          "last_name": "Петров",
          "father_name": "Отчество",
          "avatar_color": "pink",
          "avatar": null,
          "background_image": null,
          "email": "sobaka@sbk.ru",
        },
        files: [],
        text: "Текст комментария",
        pinned: true,
        timestamp: "2007-05-23 09:15:28"
      },
      {
        id: generateKey(),
        author: {
          "id": generateKey(),
          "first_name": "Федька",
          "last_name": "Петров",
          "father_name": "Отчество",
          "avatar_color": "pink",
          "avatar": null,
          "background_image": null,
          "email": "sobaka@sbk.ru",
        },
        files: [],
        text: "Текст второго комментария",
        pinned: false,
        timestamp: "2007-05-23 09:15:28"
      }
    ],
    interface: {
      theme: "light",
      lang: "ru",
      backgrounds: {
        images: [1, 2, 3, 4],
        colors: [
          "red",
          "green",
          "yellow",
          "pink",
          "purple",
          "black",
          "orange",
        ]
      }
    },
    companies: [
      {
        name: "ОАУ Компания",
        id: generateKey(),
        inn: 123654654,
        phone: '+7989875465',
        director: {
          "id": generateKey(),
          "first_name": "Карл",
          "last_name": "Карлов",
          "father_name": "Кукухович",
          "avatar_color": "#E41E5b",
          "avatar": null,
          "background_image": null,
        },
        mail: "pochtaRossii@pr.ru",
      },
      {
        name: "ЪЫЬ ГраЪлЬ",
        id: generateKey(),
        inn: 123654654,
        phone: '+7989875488',
        director: {
          "id": generateKey(),
          "first_name": "Зигмунд",
          "last_name": "Фрейд",
          "father_name": null,
          "avatar_color": "#E41E5b",
          "avatar": null,
          "background_image": null,
        },
        mail: "sigmund@fr.de",
      },
    ]
  }

  const me = useSelector(selectMe)

  const user = +me.user?.id === +userId ? me : defaultUser;
  const itsMe = +me.user?.id === +userId;

  return (
    <div className={classNames('page', 'flex')}>
      <Suspense fallback={<Preloader />}>
        <UserHeader
          name={user?.user?.first_name}
          surname={user?.user?.last_name}
          patronymic={user?.user?.father_name}
        />
        <div
          className={classNames('pageContent', 'pageShadow')}
          style={{ backgroundImage: `url(${me?.user?.background_image})` }}
        >
          <UserContent
            user={user}
            itsMe={itsMe}
            userId={userId}
          />
        </div>
      </Suspense>
    </div>
  );
}
