import React from "react";
import { createPortal } from "react-dom";
import SubTaskPopup from "./SubTaskPopup";

export default function SubTaskPopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <SubTaskPopup
          taskId={props.taskId}
          handleClickClose={props.handleClickClose}
        />
      ), document.getElementById('modal_root'))
      :
      <SubTaskPopup
        taskId={props.taskId}
        handleClickClose={props.handleClickClose}
      />
  )
}
