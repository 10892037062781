import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from './imgpopup.module.css';

export default function ImgPopup(props) {
  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {
      document.removeEventListener('mouseup', outsideHandleClick);
    }
  }, [])

  const [imageSize, setImageSize] = useState({ width: '', height: '' })
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  const [resultSizing, setResultSizing] = useState(null) // значения назначаем width, height, oneToOne

  useEffect(() => {
    const img = new Image()
    img.src = props.file
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    })

    return () => { window.removeEventListener('resize', () => { }) }
  }, [])

  useEffect(() => {
    const sizeWindow = windowSize.height / windowSize.width;
    const sizeImage = imageSize.height / imageSize.width;

    if (sizeWindow > sizeImage) {
      setResultSizing('width')
    } else if (sizeWindow < sizeImage) {
      setResultSizing('height')
    } else {
      setResultSizing('oneToOne')
    }
  }, [windowSize, imageSize])

  return (

    props.insideParent ?
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames('flex', 'popup', styles.imgPopup)}
      >
        <div className={classNames(
          'flex',
          'imageBox',
          'shadow',
          styles.imageInPopup,
          resultSizing === 'width' ? styles.width : resultSizing === 'height' ? styles.height : styles.oneToOne
        )}>
          <img src={props.file} ref={ref} />
        </div>
        <button
          className={classNames(styles.deleteFileBtn)}
          onClick={props.handleClickClose}
        >
          <span className={classNames(styles.horLine, styles.line)}></span>
          <span className={classNames(styles.verLine, styles.line)}></span>
        </button>
      </div>
      :
      createPortal((
        <div
          onClick={(e) => e.stopPropagation()}
          className={classNames('flex', 'popup', styles.imgPopup)}
        >
          <div className={classNames(
            'flex',
            'imageBox',
            'shadow',
            styles.imageInPopup,
            resultSizing === 'width' ? styles.width : resultSizing === 'height' ? styles.height : styles.oneToOne
          )}>
            <img src={props.file} ref={ref} />
          </div>
          <button
            className={classNames(styles.deleteFileBtn)}
            onClick={props.handleClickClose}
          >
            <span className={classNames(styles.horLine, styles.line)}></span>
            <span className={classNames(styles.verLine, styles.line)}></span>
          </button>
        </div>
      ), document.getElementById('modal_root'))

  )
}
