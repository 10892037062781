import { io } from "socket.io-client";

export const socket = io(
  'https://api.boom-track.ru/',

  {
    transports: ['websocket', 'polling', 'flashsocket']
  });

socket.on('connect', () => {
  console.log('Connected to server', socket.id);
});
socket.on('disconnect', () => {
  console.log('Disconnected from server', socket.id);
  socket.removeAllListeners('getBoard')
}
);

