import React from 'react';

export default function UserIcon(props) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3099_332)">
        <path d="M9 9.5C9.89002 9.5 10.76 9.23608 11.5001 8.74162C12.2401 8.24715 12.8169 7.54434 13.1575 6.72208C13.4981 5.89981 13.5872 4.99501 13.4135 4.1221C13.2399 3.24918 12.8113 2.44736 12.182 1.81802C11.5526 1.18869 10.7508 0.760102 9.87791 0.586468C9.00499 0.412835 8.10019 0.50195 7.27792 0.842544C6.45566 1.18314 5.75285 1.75991 5.25839 2.49994C4.76392 3.23996 4.5 4.10999 4.5 5C4.50119 6.19311 4.97568 7.33701 5.81934 8.18067C6.66299 9.02432 7.80689 9.49881 9 9.5ZM9 2C9.59334 2 10.1734 2.17595 10.6667 2.50559C11.1601 2.83524 11.5446 3.30377 11.7716 3.85195C11.9987 4.40013 12.0581 5.00333 11.9424 5.58527C11.8266 6.16722 11.5409 6.70177 11.1213 7.12132C10.7018 7.54088 10.1672 7.8266 9.58527 7.94236C9.00333 8.05811 8.40013 7.9987 7.85195 7.77164C7.30377 7.54458 6.83524 7.16006 6.50559 6.66671C6.17595 6.17337 6 5.59335 6 5C6 4.20435 6.31607 3.44129 6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2Z" fill="#3E5196" />
        <path d="M9 11C7.2104 11.002 5.49466 11.7138 4.22922 12.9792C2.96378 14.2447 2.25199 15.9604 2.25 17.75C2.25 17.9489 2.32902 18.1397 2.46967 18.2803C2.61032 18.421 2.80109 18.5 3 18.5C3.19891 18.5 3.38968 18.421 3.53033 18.2803C3.67098 18.1397 3.75 17.9489 3.75 17.75C3.75 16.3576 4.30312 15.0223 5.28769 14.0377C6.27226 13.0531 7.60761 12.5 9 12.5C10.3924 12.5 11.7277 13.0531 12.7123 14.0377C13.6969 15.0223 14.25 16.3576 14.25 17.75C14.25 17.9489 14.329 18.1397 14.4697 18.2803C14.6103 18.421 14.8011 18.5 15 18.5C15.1989 18.5 15.3897 18.421 15.5303 18.2803C15.671 18.1397 15.75 17.9489 15.75 17.75C15.748 15.9604 15.0362 14.2447 13.7708 12.9792C12.5053 11.7138 10.7896 11.002 9 11Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_3099_332">
          <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
