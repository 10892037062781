import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../../../../../../../../../API/socket";
import { editDateForInput, editDateToFull } from "../../../../../../../../../../utils/dateEditor";
import DatePick from "../../../../../../../../../common/DatePick/DatePick";
import ColsList from "./ColsList/ColsList";
import styles from './taskmeta.module.css';

export default function TaskMeta(props) {

  const [editDate, setEditDate] = useState(false);
  const [date, setDate] = useState(props.date);
  const [showCols, setShowCols] = useState(false);

  const handlePickDate = (startDate) => {

    (new Date(startDate).getTime() !== new Date(props.date).getTime()) &&
      socket.emit('ChangeTaskDeadline', {
        'taskId': props.taskId,
        'deadline': startDate ? editDateForInput(startDate) : null
      })
    setEditDate(false)
  }

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handlePickDate(date)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {
      document.removeEventListener('mouseup', outsideHandleClick);
    }
  }, [date])

  return (
    <div className={classNames('flex', styles.metaWrap)}>
      <div className={classNames('flex', styles.metaField)}>
        <p className={styles.metaHeader}>
          В колонке
        </p>
        <p
          className={classNames(styles.colName, (props.columns?.length > 0 && !props.readOnly) && styles.pointer)}
          onClick={() => {
            (props.columns?.length > 0 && !props.readOnly) &&
              setShowCols(true)
          }}
        >
          {props.colName}
          {
            showCols &&
            <ColsList
              taskId={props.taskId}
              columns={props.columns}
              handleClickClose={setShowCols}
            />
          }
        </p>

      </div>
      {
        props.date &&
        <div className={classNames('flex', styles.metaField)}>
          <p className={styles.metaHeader}>
            Срок
          </p>
          {
            !editDate ?
              <p
                onClick={() => {
                  !props.readOnly &&
                  setEditDate(true)
                }}
              >
                {editDateToFull(props.date)}
              </p>
              :
              <div className={styles.pickWrapper} ref={ref}>
                <DatePick
                  inTask={true}
                  setDate={setDate}
                  autofocus={true}
                  handlePickDate={handlePickDate}
                  startDate={props.date ? new Date(editDateForInput(props.date)) : null}
                />
              </div>
          }

        </div>
      }

    </div>
  )
}
