import classNames from 'classnames';
import React from 'react';
import styles from './fulltimer.module.css';
import TimerControls from './TimerControls/TimerControls';

export default function FullTimer(props) {

  return (
    <div className={classNames('flex', styles.full)}>
      <div className={styles.controls}>
        <TimerControls
          timerStatus={props.timerStatus}
          startTimer={props.startTimer}
          pauseTimer={props.pauseTimer}
          time={props.time}
        />
      </div>
      <p className={styles.description}>
        При создании этапа, который предполагает прохождения на сайте, необходимо реализовать два варианта (в описании)
      </p>
      <p className={styles.path}>
        Проект / Доска / Колонка /
      </p>
    </div>
  );
}
