import React from 'react';
import styles from './preloader.module.css';
import preloader from '../../../assets/images/preloader.gif';

export default function Preloader() {
  return (
    <div className={styles.preloaderBox}>
      <img src={preloader} alt='preloader' />
    </div>
  )
}
