import React, { useEffect, useState, lazy, Suspense } from 'react';
import styles from './content.module.css';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Timer from './Timer/Timer';
import { useRef } from 'react';
import CompanyContainer from './Company/CompanyContainer';
import UserPage from './UserPage/UserPage';
import Projects from './Projects/Projects';
import { selectMe } from '../../../redux/authSelectors';
import { useSelector } from 'react-redux';
import NewsContainer from './News/NewsContainer';
import NewsItemPageContainer from './NewsItemPage/NewsItemPageContainer';
import DashBoardContainer from './DashBoard/DashBoardContainer';
import ChatContainer from './Chat/ChatContainer';
import Welcome from './Welcome/Welcome';

export default function Content(props) {

  const [isBoard, setIsBoard] = useState(false);

  const href = useLocation();
  const me = useSelector(selectMe);
  const navigate = useNavigate();

  useEffect(() => {
    if (href.pathname.includes('board')) {
      setIsBoard(true)
    } else {
      setIsBoard(false)
    }

    if (href.pathname === "/" && me?.active_organization?.id) {
      navigate(`/company/${me?.active_organization?.id}`)
    }
  }, [href.pathname, me])

  return (
    <main
      className={classNames(styles.content, isBoard && styles.maxHeight)}
    >
        <Routes>
          <Route path='/' element={<CompanyContainer />} />
          <Route path='company/:companyId?/*' element={<CompanyContainer />} />
          <Route path='projects/:projectId?/*' element={<Projects isBoard={isBoard} width={props.width} />} />
          <Route path='user/:userId?/*' element={<UserPage />} />
          <Route path='news/:categoryId?/*' element={<NewsContainer />} />
          <Route path='news-item/:newsId?/*' element={<NewsItemPageContainer />} />
          <Route path='dash' element={<DashBoardContainer />} />
          <Route path='welcome' element={<Welcome />} />
        </Routes>
      <Timer />
      <ChatContainer />
    </main>
  );
}
