import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProjectsForBoard } from "../../../../../../../../../../redux/BoardPage/сolumnSelector";
import { getProjectsForBoard } from "../../../../../../../../../../redux/BoardPage/сolumnsReducer";
import TaskMovePopup from "./TaskMovePopup";

export default function TaskMovePopupWrapper(props) {

  const dispatch = useDispatch();
  const projects = useSelector(selectProjectsForBoard);
  useEffect(() => {
    dispatch(getProjectsForBoard())
  }, [dispatch])

  return (
    !props.insideParent ?
      createPortal((
        <TaskMovePopup
          keyAct={props.keyAct}
          handleClickClose={props.handleClickClose}
          projects={projects}
          taskComm={props.taskComm}
          taskAddit={props.taskAddit}
          taskCheck={props.taskCheck}
          taskName={props.taskName}
          taskId={props.taskId}
          subtasks={props.subtasks}
        />
      ), document.getElementById('modal_root'))
      :
      <TaskMovePopup
        keyAct={props.keyAct}
        handleClickClose={props.handleClickClose}
        projects={projects}
        taskComm={props.taskComm}
        taskAddit={props.taskAddit}
        taskCheck={props.taskCheck}
        taskName={props.taskName}
        taskId={props.taskId}
        subtasks={props.subtasks}
      />
  )
}
