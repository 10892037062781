import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import styles from './chat.module.css';
import FullChat from './FullChat/FullChat';
import chatIcon from '../../../../assets/images/chat/chatIcon.png'

export default function Chat(props) {

  const [isFull, setIsFull] = useState(false)

  return (
    <>
      {
        !isFull &&
        <button
          className={classNames(styles.chatBtn, 'chat')}
          onClick={() => setIsFull(true)}
        >
          <img src={chatIcon} alt='chat' />
        </button>
      }
      {
        isFull &&
        <FullChat
          setIsFull={setIsFull}
          chat={props.chat}
          send={props.send}
          defaultMessage={props.defaultMessage}
          setDefaultMessage={props.setDefaultMessage}
          loading={props.loading}
        />
      }
    </>
  );
}
