import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../svgIcons/CloseIcon";
import DeleteIcon from "../../../svgIcons/DeleteIcon";
import { FileBase64 } from "../../../utils/fileToBase64";
import styles from './addfilespopup.module.css';

export default function AddFilesPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])


  const [filesList, setFilesList] = useState([]);
  const [filesListForSocket, setFilesListForSocket] = useState([])
  const [dragActive, setDragActive] = useState(false);
  const [link, setLink] = useState({ fileData: '', fileName: '', isExternal: true });

  const nameFilter = (array) => {
    const collection = new Set()
    return array.filter(file => {
      if (collection.has(file.name)) return false
      collection.add(file.name)
      return true
    })
  }

  const handleChange = (e) => {
    e.preventDefault();
    e.target.files?.length > 0 && setFilesList(nameFilter([...filesList, ...e.target.files]));
  }

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(true)
  }
  const handleLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false)
  }

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false);

    const dropFiles = props.type === 'video' ?
      [...e.dataTransfer.files].filter(item => item.type.includes('video')) :
      props.type === 'audio' ?
        [...e.dataTransfer.files].filter(item => item.type.includes('audio')) :
        props.type === 'photo' ?
          [...e.dataTransfer.files].filter(item => item.type.includes('image')) : [...e.dataTransfer.files]

    dropFiles?.length > 0 && setFilesList(prev => nameFilter([...prev, ...dropFiles]));
  }

  const handleDeleteFile = (arr, item) => {
    const filtered = arr.filter((el) => el.name !== item.name)
    setFilesList(filtered);
  }

  useEffect(() => {
    setFilesListForSocket(filesList.map(item => {
      if (item.isExternal) {
        return {
          name: item.fileName,
          external_url: item.fileData
        }
      } else {
        return {
          "file": item,
          "name": item.name
        }
      }
    }))
    console.log(filesList)
    console.log(filesListForSocket)
  }, [filesList])

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={'popupWrapper'}>
        <div className={classNames('flex', 'popupWindow', 'shadow', styles.filesWindow)} ref={ref}>
          {props.type &&
            <div className={styles.typeTitle}>
              {
                props.type === 'video' ?
                  'Добавить видео' :
                  props.type === 'audio' ?
                    'Добавить аудио' :
                    props.type === 'file' ?
                      'Добавить файл' :
                      props.type === 'photo' ?
                        'Добавить картинку' : 'Добавить ссылку'
              }
            </div>
          }
          {
            (!props.type || props.type != 'link') &&
            <div
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleLeave}
              onDrop={handleDrop}
              className={classNames('flex', styles.filesWrapper, dragActive && styles.activeWrapper)}
            >
              <h2>Переместить файл</h2>
              <div>
                <label
                  className={classNames(styles.fileLabel)}
                  htmlFor="files"
                >
                </label>
                <p>Выбрать с компьютера</p>
                <input
                  className={classNames('fileInput')}
                  type='file'
                  multiple
                  name='files'
                  id='files'
                  onChange={handleChange}
                  accept={
                    props.type === 'video' ?
                      'video/*' :
                      props.type === 'audio' ?
                        'audio/*' :
                        props.type === 'photo' ?
                          'image/*' :
                          '*'
                  }
                />
              </div>
            </div>
          }
          {
            props.withLinks &&
            <div className={styles.linksWrapp}>
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Вставка ссылки</p>
                <input
                  className={classNames('input')}
                  type='text'
                  name='link'
                  placeholder='Ссылка'
                  value={link.fileData}
                  onChange={(e) => setLink(state => ({ ...state, fileData: e.target.value }))}
                />
              </div>
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Текст отображения</p>
                <input
                  className={classNames('input')}
                  type='text'
                  name='name'
                  value={link.fileName}
                  onChange={(e) => setLink(state => ({ ...state, fileName: e.target.value }))}
                  placeholder='Текст'
                />
              </div>
              <button
                className={classNames('btn', styles.addLink)}
                disabled={!link.fileData.length || !link.fileName.length}
                onClick={() => {
                  setFilesList(nameFilter([...filesList, { fileData: link.fileData, fileName: link.fileName, isExternal: true }]))
                  setLink({ fileData: '', fileName: '', isExternal: true })
                }}
              >
                Добавить ссылку в список
              </button>
            </div>
          }
          {
            filesList.length > 0 &&
            <div className={styles.filesList}>
              {
                filesList.map((item, index) =>
                  <div
                    key={index}
                    className={classNames('flex', styles.fileItem)}
                  >
                    {item.name || item.fileName}
                    <button
                      className={classNames('iconBtn')}
                      onClick={() => handleDeleteFile(filesList, item)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                )
              }
            </div>
          }
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
          <div className={classNames('popupBtnsWrapper', styles.btnsWrapper)}>
            {/* {props.files?.length > 0 &&
              <button button
                className={classNames('btn', 'btnTransparent')}
                onClick={() => {
                  setFilesList([]);
                }}
              >
                Очистить
              </button>
            } */}
            <button
              className={classNames('btn', styles.confirmBtn)}
              onClick={() => {
                props.forTasks ? props.uploadFiles(filesListForSocket) : props.setFiles(prev => nameFilter([...prev, ...filesList]));
                props.handleClickClose();
              }}
            >
              {props.forTasks ? "Загрузить" : "Добавить"}
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}
