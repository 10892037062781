import classNames from 'classnames';
import React from 'react';
import { editNameWithoutDots } from '../../../../../../../../../../utils/nameEditor';
import styles from './taskuser.module.css';

export default function TaskUser(props) {

  return (
    <div
      style={{backgroundColor : `${props.user?.user?.avatar_color}`}}
      className={classNames('flex', styles.user)}
    >
      {editNameWithoutDots(props.user?.user?.first_name)}{editNameWithoutDots(props.user?.user?.last_name)}
    </div>
  );
}
