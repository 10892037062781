import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBoard, editBoard } from '../../../../../../../redux/boardsListReducer';
import DeleteIcon from '../../../../../../../svgIcons/DeleteIcon';
import FromArchiveIcon from '../../../../../../../svgIcons/FromArchiveIcon';
import ConfirmWindow from '../../../../../../common/ConfirmWindow/ConfirmWindow';
import styles from './archiveditem.module.css';

export default function ArchivedItem(props) {
  const dispatch = useDispatch();

  const handleRestoreBoard = () => {
    dispatch(editBoard(
      {
        boardId: props.id,
        archived: false,
      }
    ))
    .then(props.closePopup(false))
  }

  return (
    <div
      className={classNames('flex', styles.archivedItem)}
    >
      {props.name}
      <div
        className={classNames('flex', 'iconBtnGroup')}
      >
        <button
          className={classNames('flex', 'iconBtn')}
          onClick={() => {
            props.setBoardId(props.id)
            props.handleClickOpenConfirm()
            props.closePopup(false)
          }}
        >
          <DeleteIcon />
        </button>
        <button
          className={classNames('flex', 'iconBtn')}
          onClick={handleRestoreBoard}
        >
          <FromArchiveIcon />
        </button>
      </div>

    </div>
  );
}
