import React, { useEffect } from 'react';
import { useRef } from 'react';
import styles from './commentitem.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import CommentAddFiles from '../../CommentAddFiles/CommentAddFiles';
import CommentAccess from '../../CommentAccess/CommentAccess';
import CommentFiles from './CommentFiles/CommentFiles';
import UserInfo from '../../../UserInfo/UserInfo';
import { editDateToFull, editTime } from '../../../../../utils/dateEditor';
import PreviewFiles from '../../../PreviwFiles/PreviewFiles';
import Emoji from '../../Emoji/Emoji';
import EditSmallIcon from '../../../../../svgIcons/EditSmallIcon';
import Editor from '../../../Editor/Editor';


export default function CommentItemEdit(props) {

  const {
    clearErrors,
    setError,
    getValues,
    setValue,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  const [filesList, setFilesList] = useState(props.item.files?.slice());
  const [newFilesList, setNewFilesList] = useState([]);
  const [deletedFilesList, setDeletedFilesList] = useState([]);
  const [accessValue, setAccessValue] = useState(props.item.display_to_customer);
  const refWrapper = useRef(null);

  const handleKeyDown = (e) => {

    if (e.code.includes('Enter') && e.shiftKey) {
      return
    }
    if (e.code.includes('Enter') && !e.shiftKey) {
      e.preventDefault()
      onSubmit(getValues())
    }
  }

  const handleDeleteFile = (arr, item) => {
    const filtered = arr.filter((el) => el.id !== item.id)
    setFilesList(filtered);
    setDeletedFilesList(prev => [...prev, item.id]);
  }
  const handleDeleteNewFile = (arr, item) => {
    const filtered = arr.filter((el) => el.name !== item.name)
    setNewFilesList(filtered);
  }

  // const filtered = JSON.parse(sessionStorage.getItem(`commentsFiltered${props.item.project}`));
  // const filteredData = JSON.parse(sessionStorage.getItem(`commentsFilteredDatas${props.item.project}`));

  // const handleEditText = (data) => {
  //   dispatch(editComment(
  //     {
  //       id: props.item.id,
  //       text: data.text,
  //       project: props.item.project,
  //       author: props.item.author?.id,
  //       files: filesList.concat(newFilesList),
  //       display_to_customer: accessValue
  //     }
  //   ))
  //     .then(handleEdit)
  //     .then(() => {
  //       if (filtered) {
  //         dispatch(getFilteredComments(
  //           {
  //             projectId: props.item.project,
  //             date: filteredData?.date,
  //             for_all: filteredData?.for_all,
  //             hidden: filteredData?.hidden,
  //             personal: filteredData?.personal,
  //           }
  //         ))
  //       } else {
  //         return;
  //       }
  //     })
  // }

  // For editor (code from lib)
  const [editorHtml, setEditorHtml] = useState(props.item.text);
  const [quillRef, setQuillRef] = useState(null);
  const reactQuillRef = useRef();

  const attachQuillRefs = () => {
    if (!reactQuillRef.current || typeof reactQuillRef.current.getEditor !== 'function') return;
    if (quillRef) return;

    const editor = reactQuillRef.current.getEditor();
    if (editor !== null) setQuillRef(editor);
  };

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  useEffect(() => {
    attachQuillRefs();
  }, []);

  // ADD Emoji in Quill Editor (body of func is from lib)

  const emojiClick = (emojiObject) => {
    var range = quillRef.getSelection();
    let position = range ? range.index : 0;
    quillRef.insertText(position, emojiObject.native);

    console.log(emojiObject)
  }

  const showEditor = () => {
    refWrapper.current.classList.toggle('active');
  }

  const onSubmit = (() => {
    props.inTask ?
      props.handleEdit(props.item.id, newFilesList, editorHtml, filesList)
      :
      props.handleEdit(props.item.id, filesList.concat(newFilesList), editorHtml, accessValue, props.item.author?.id)

    props.setIsEdit(false)
  })

  return (
    <form
      className={classNames('flex', styles.comment, styles.editCommentContainer)}
      onSubmit={handleSubmit(onSubmit)}
      onChange={() => {
        clearErrors('serverError');
      }}
    >
      <div className={classNames('flex', styles.author)}>
        <UserInfo
          avatar={props.item.author.avatar}
          first_name={props.item.author.first_name}
          last_name={props.item.author.last_name}
          email={props.item.author.email}
          isFull={false}
          color={props.item.author?.avatar_color}
        />
        <div className={classNames(styles.authorName, styles.mobile)}>
          {props.item.author?.first_name} {props.item.author?.last_name}
        </div>
      </div>
      <div className={styles.commentContent}>
        <div className={classNames(styles.authorName, styles.desktop)}>
          {props.item.author?.first_name} {props.item.author?.last_name}
        </div>
        <div className={styles.commentTextWrapper}>
          <div className={styles.commentText}>
            <div
              className={classNames('quillWrapper', 'editQuillWrapper', styles.areaWrapper)}
              ref={refWrapper}
            >
              <Editor
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                editorHtml={editorHtml}
                reactQuillRef={reactQuillRef}
              />
            </div>
          </div>
        </div>
        {
          props.item?.files?.length > 7 ?
            <>
              <p
                className={styles.showFilesBtn}
                onClick={props.handleShowFiles}
              >
                {props.isShowFiles ? 'Скрыть вложения' : 'Показать вложения' + ' ' + `${'(' + props.item?.files?.length + 'шт)'}`}
              </p>
              {
                props.isShowFiles &&
                <CommentFiles
                  handleDeleteFile={handleDeleteFile}
                  filesList={filesList}
                  editFile={true}
                />
              }
            </>
            :
            props.item?.files?.length > 0 ?
              <CommentFiles
                handleDeleteFile={handleDeleteFile}
                filesList={filesList}
                editFile={true}
              />
              :
              null
        }
        {
          newFilesList.length > 0 &&
          <PreviewFiles
            files={newFilesList}
            handleDeleteFile={handleDeleteNewFile}
          />
          // <div>Добавлено новых файлов: {newFilesList.length}</div>
        }
      </div>
      <div className={styles.commentMeta}>
        <span className={styles.date}>
          {editDateToFull(props.item.timestamp)}
        </span>
        <span className={styles.time}>
          {editTime(props.item.timestamp)}
        </span>
      </div>
      <div className={classNames('flex', styles.commentBtns)}>
        <button
          type='button'
          className={classNames('btn', 'btnTransparent', styles.smallbtn)}
          onClick={() => props.setIsEdit(false)}
        >
          Отмена
        </button>
        <button
          type='submit'
          className={classNames('btn', styles.smallbtn)}
        >
          Готово
        </button>
      </div>
      <div className={classNames('flex', styles.editControls)}>
        <button
          type='button'
          onClick={showEditor}
          className={classNames('flex', 'iconBtn')}
        >
          <EditSmallIcon />
        </button>
        {!props.withoutFiles &&
          <CommentAddFiles
            files={newFilesList}
            setFiles={setNewFilesList}
            insideParent={props.insideParent}
          />
        }
        <Emoji
          emojiClick={emojiClick}
        />
        {
          props.access &&
          <CommentAccess
            setAccessValue={setAccessValue}
            isCheck={accessValue}
          />
        }

      </div>
    </form>
  );
}
