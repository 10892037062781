import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './boardlink.module.css';
import classNames from 'classnames';
import DotsIcon from '../../../../../../svgIcons/DotsIcon';
import { useState } from 'react';
import BoardControls from '../BoardControls/BoardControls';
import { useDispatch } from 'react-redux';
import { deleteBoard, duplicateBoard, editBoard } from '../../../../../../redux/boardsListReducer';
import BoardMovePopup from '../BoardMovePopup/BoardMovePopup';
import DeleteIcon from '../../../../../../svgIcons/DeleteIcon';
import CopyIcon from '../../../../../../svgIcons/CopyIcon';
import ShareIcon from '../../../../../../svgIcons/ShareIcon';
import EditSmallIcon from '../../../../../../svgIcons/EditSmallIcon';
import MoveIcon from '../../../../../../svgIcons/MoveIcon';
import ArchiveIcon from '../../../../../../svgIcons/ArchiveIcon';
import ConfirmWindow from '../../../../../common/ConfirmWindow/ConfirmWindow';
import SharePopupWrapper from '../../../../../common/SharePopup/SharePopupWrapper';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

export default function BoardLink(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef(null)

  const [isOpenControls, setIsOpenControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [changeText, setChangeText] = useState(props.item.name);
  const [isOpenMovePopup, setIsOpenMovePopup] = useState(false);
  const [isOpenSharePopup, setIsOpenSharePopup] = useState(false);
  const [actKey, setActKey] = useState('');

  const handleDuplicateBoard = () => {
    dispatch(duplicateBoard(
      {
        boardId: props.item.id,
      }
    ))
  }

  const handleRename = (e) => {
    if (e.target.value.length > 0) {
      dispatch(editBoard(
        {
          boardId: props.item.id,
          name: e.target.value,
          archived: undefined,
        }
      ))
    } else {
      return
    }
  }
  const handleArchiveBoard = () => {
    dispatch(editBoard(
      {
        boardId: props.item.id,
        archived: true,
      }
    ))
      .then(handleClickCloseConfirm)
      .then(() => {
        if ((window.location.href).includes(`board/${props.item.id}`)) {
          navigate(`/projects/${props.item?.project}`)
        }
      })
  }

  const handleClickOpenMove = (key) => {
    setActKey(key)
    setIsOpenMovePopup(true)
    document.body.classList.add('modal-show');
  }
  const handleClickCloseMove = () => {
    setIsOpenMovePopup(false)
    document.body.classList.remove('modal-show');
    setActKey('')
  }
  const handleClickOpenShare = () => {
    setIsOpenSharePopup(true)
    document.body.classList.add('modal-show');
  }
  const handleClickCloseShare = () => {
    setIsOpenSharePopup(false)
    document.body.classList.remove('modal-show');
  }

  const handleClickOpenConfirm = (key) => {
    setActKey(key)
    setIsOpenConfirmPopup(true)
    document.body.classList.add('modal-show');
  }

  const handleClickCloseConfirm = () => {
    setIsOpenConfirmPopup(false)
    document.body.classList.remove('modal-show');
    setActKey('')
  }

  const handleDeleteBoard = () => {
    dispatch(deleteBoard(props.item.id))
      .then(handleClickCloseConfirm)
      .then(() => {
        if ((window.location.href).includes(`board/${props.item.id}`)) {
          navigate(`/projects/${props.item?.project}`)
        }
      })
  }

  const controlsList = [
    {
      icon: <EditSmallIcon />,
      name: 'Переименовать',
      callBack: () => setIsEditName(true),
      id: 1,
    },
    {
      icon: <CopyIcon />,
      name: 'Копировать в...',
      callBack: handleClickOpenMove,
      id: 2,
      key: 'copy'
    },
    {
      icon: <MoveIcon />,
      name: 'Переместить в...',
      callBack: handleClickOpenMove,
      id: 3,
      key: 'move'
    },
    {
      icon: <CopyIcon />,
      name: 'Дублировать',
      callBack: handleDuplicateBoard,
      id: 4,
    },
    // {
    //   icon: <ShareIcon />,
    //   name: 'Поделиться',
    //   callBack: handleClickOpenShare,
    //   id: 5,
    // },
    {
      icon: <ArchiveIcon />,
      name: 'Архивировать',
      callBack: handleClickOpenConfirm,
      id: 6,
      key: 'archive'
    },
    {
      icon: <DeleteIcon />,
      name: 'Удалить',
      callBack: handleClickOpenConfirm,
      id: 7,
      key: 'delete'
    },
  ]

  return (
    <div className={styles.linkWrapper} ref={ref}>
      {
        isEditName
          ?
          <div className={classNames('flex', styles.board)}>
            <p className={styles.backRename}>
              {changeText}
              <input
                className={styles.renameBoard}
                autoFocus={true}
                defaultValue={props.item.name}
                onChange={(e) => setChangeText(e.target.value)}
                onBlur={(e) => {
                  handleRename(e)
                  setIsEditName(false)
                }}
              >
              </input>
            </p>
          </div>
          :
          <NavLink
            to={`board/${props.item.id}`}
            className={({ isActive }) => isActive ? classNames(styles.active, 'flex', styles.board) : classNames('flex', styles.board)}
          >
            <div className={classNames(styles.radius, styles.radiusLeft)}>
              <div></div>
            </div>
            <div className={classNames(styles.radius, styles.radiusRight)}>
              <div></div>
            </div>
            {props.item.name}
          </NavLink>
      }
      <button
        type='button'
        onClick={() => setIsOpenControls(true)}
        className={classNames('flex', 'iconBtn', styles.boardBtn)}
      >
        <DotsIcon />
      </button>
      {
        isOpenControls &&
        createPortal((
          <div
            style={{
              top: `${(ref.current?.getBoundingClientRect().y || 0) + ref.current?.getBoundingClientRect().height + window.scrollY}px`,
              left: `${(ref.current?.getBoundingClientRect().x || 0)}px`,
            }}
            className={classNames('absolutePortal', styles.controlPortal)}
          >
            <BoardControls
              handleClickClose={setIsOpenControls}
              controlsList={controlsList}
            />
          </div>
        ), document.getElementById('modal_root'))
      }
      {
        isOpenMovePopup &&
        <BoardMovePopup
          moveKey={actKey}
          item={props.item}
          popupHeader={actKey === 'move' ? 'Перемещение доски' : 'Копирование доски'}
          submitText={actKey === 'move' ? 'Переместить' : 'Копировать'}
          handleClickClose={handleClickCloseMove}
        />
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          title={actKey === 'delete' ? 'Удалить доску?' : 'Архивировать доску?'}
          onDelete={actKey === 'delete' ? handleDeleteBoard : handleArchiveBoard}
          btnText={actKey === 'delete' ? 'Удалить' : 'Архивировать'}
          closeDelete={handleClickCloseConfirm}
        />
      }
      {
        isOpenSharePopup && <SharePopupWrapper
          handleClickClose={handleClickCloseShare}
          popupHeader={`Поделиться доской - ${props.item.name}`}
        />
      }
    </div>
  );
}
