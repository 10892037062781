import classNames from "classnames";
import React, { useState } from "react";
import { socket } from "../../../../../../../../../../../API/socket";
import DeleteIcon from "../../../../../../../../../../../svgIcons/DeleteIcon";
import EditIcon from "../../../../../../../../../../../svgIcons/EditIcon";
import ConfirmWindow from "../../../../../../../../../../common/ConfirmWindow/ConfirmWindow";
import TagPopup from "../../../TaskPopups/TagPopup/TagPopup";
import styles from './tagitem.module.css';

export default function TagItem(props) {

  const [showControls, setShowControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const handleDeleteTag = () => {
    socket.emit("DeleteLabel",
      {
        'taskId': props.taskId,
        'labelId': props.id,
      })
    setIsOpenConfirmPopup(false)
  }

  return (
    <div
      style={{ 'backgroundColor': `${props.color}` }}
      className={classNames('tag', Boolean(props.color) && 'tagWhite', styles.cardTag, showControls && styles.botRad)}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      #{props.name}
      {
        (showControls && !props.readOnly) &&
        <div
          style={{ 'backgroundColor': `${props.color}` }}
          className={classNames('flex', styles.tagControls)}
        >
          <button
            className={classNames('flex', 'iconBtn', styles.tagBtn)}
            onClick={() => {
              setIsOpenConfirmPopup(true)
            }}
          >
            <DeleteIcon />
          </button>
          <button
            className={classNames('flex', 'iconBtn', styles.tagBtn)}
            onClick={() => {
              setIsOpenEdit(true)
            }}
          >
            <EditIcon />
          </button>
        </div>
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          insideParent={true}
          title={'Удалить метку из задачи?'}
          onDelete={handleDeleteTag}
          closeDelete={() => {
            setIsOpenConfirmPopup(false)
            setShowControls(false)
          }}
        />
      }
      {
        isOpenEdit &&
        <TagPopup
          handleClickClose={() => {
            setIsOpenEdit(false)
            setShowControls(false)
          }}
          edit={true}
          color={props.color}
          name={props.name}
          tagId={props.id}
        />
      }
    </div>
  )
}
