import React from 'react';

export default function FilterIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2234_845)">
        <path d="M15.129 -7.39745e-05H5.871C4.2885 -7.39745e-05 3 1.28768 3 2.87168C3 3.63818 3.2985 4.35893 3.84075 4.90193L8.25 9.31043V11.9999C8.25 12.2362 8.361 12.4582 8.55 12.5999L11.55 14.8499C11.682 14.9489 11.841 14.9999 12 14.9999C12.114 14.9999 12.2295 14.9737 12.3353 14.9212C12.5895 14.7944 12.75 14.5342 12.75 14.2499V9.31043L17.1593 4.90118C17.7015 4.35893 18 3.63743 18 2.87093C18 1.28768 16.7115 -0.000823975 15.129 -0.000823975V-7.39745e-05ZM16.0988 3.84143L11.4698 8.46968C11.3288 8.61068 11.25 8.80118 11.25 8.99993V12.7499L9.75 11.6249V8.99993C9.75 8.80118 9.67125 8.60993 9.53025 8.46968L4.90125 3.84143C4.6425 3.58193 4.5 3.23768 4.5 2.87168C4.5 2.11568 5.115 1.49993 5.871 1.49993H15.129C15.885 1.49993 16.5 2.11493 16.5 2.87168C16.5 3.23768 16.3575 3.58268 16.0988 3.84143ZM9.75 15.7499V17.2499C9.75 17.5342 9.5895 17.7937 9.33525 17.9212C9.22875 17.9744 9.114 17.9999 9 17.9999C8.841 17.9999 8.682 17.9489 8.55 17.8499L5.55 15.5999C5.361 15.4589 5.25 15.2362 5.25 14.9999V12.3104L0.84075 7.90193C0.2985 7.35893 0 6.63818 0 5.87168C0 5.02718 0.36975 4.22918 1.01475 3.68168C1.332 3.41318 1.8045 3.45218 2.07225 3.76793C2.34 4.08368 2.30175 4.55693 1.986 4.82543C1.67775 5.08718 1.50075 5.46893 1.50075 5.87243C1.50075 6.23843 1.64325 6.58343 1.902 6.84218L6.531 11.4704C6.672 11.6114 6.75075 11.8019 6.75075 12.0007V14.6257L8.25075 15.7507C8.25075 15.3367 8.586 15.0007 9.00075 15.0007C9.4155 15.0007 9.75075 15.3367 9.75075 15.7507L9.75 15.7499Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2234_845">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
