import { newsAPI } from "../../API/api";

const SET_NEWS_LIST = 'SET_NEWS_LIST';
const SET_NEWS_ITEM = 'SET_NEWS_ITEM';
const SET_CATEGORIES = 'SET_CATEGORIES';

let initialState = {
  newsList: {},
  newsItem: {},
  newsComments: [],
  categories: [],
  isFetching: false
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_LIST:
      return {
        ...state,
        newsList: action.newsList,
      }
    case SET_NEWS_ITEM:
      return {
        ...state,
        newsItem: action.newsItem,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      }
    default: return { ...state };
  }
}

const setNews = (newsList) => ({ type: SET_NEWS_LIST, newsList });
const setNewsItem = (newsItem) => ({ type: SET_NEWS_ITEM, newsItem });
const setCategories = (categories) => ({ type: SET_CATEGORIES, categories });

export const getNewsList = ({category, page, pageSize}) => async (dispatch) => {
  await newsAPI.getNewsList({category, page, pageSize})
    .then(response => dispatch(setNews(response.data)))
    .catch(err => console.log(err))
}

export const getCategories = () => async (dispatch) => {
  await newsAPI.getCategories()
    .then(response => dispatch(setCategories(response.data)))
    .catch(err => console.log(err))
}

export const getNewsItem = (id) => async (dispatch) => {
  return await newsAPI.getNewsItem(id)
    .then(response => {
      dispatch(setNewsItem(response.data));
    })
    .catch(err => console.log(err))
}


