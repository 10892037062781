import { companyAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_COMPANY_INFO = 'SET_COMPANY_INFO';

let initialState = {
  companyInfo: {},
  isFetching: false
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.company,
      }
    default: return { ...state };
  }
}

const setCompany = (company) => ({ type: SET_COMPANY_INFO, company });

export const getCompanyInfo = (organizationId) => async (dispatch) => {
  await companyAPI.getCompanyInfo(organizationId)
    .then(response => dispatch(setCompany(response.data)))
    .catch(err => console.log(err))
}

export const editCompanyInfo = ({
  companyId: companyId,
  name: name,
  inn: inn,
  email: email,
  phone: phone,
  director: director
}) => async (dispatch) => {
  return await companyAPI.editCompanyInfo({
    companyId: companyId,
    name: name,
    inn: inn,
    email: email,
    phone: phone,
    director: director
  })
    .then(response => dispatch(setCompany(response.data)))
    .catch(err => {
      throw err
    })
}
