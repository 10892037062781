import React from "react";
import { createPortal } from "react-dom";
import AddFilesPopup from "../../../../../../../../../common/AddFilesPopup/AddFilesPopup";

export default function TaskFilesPopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <AddFilesPopup
          forTasks={props.forTasks}
          files={props.files}
          handleClickClose={props.handleClickClose}
          setFiles={props.setFiles}
          withLinks={props.withLinks}
          uploadFiles={props.uploadFiles}
        />
      ), document.getElementById('modal_root'))
      :
      <AddFilesPopup
        forTasks={props.forTasks}
        files={props.files}
        handleClickClose={props.handleClickClose}
        setFiles={props.setFiles}
        withLinks={props.withLinks}
        uploadFiles={props.uploadFiles}
      />
  )
}
