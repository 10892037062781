import React, { useState } from 'react';
import styles from './commentfiles.module.css';
import classNames from 'classnames';
import FileIcon from '../../../../../../svgIcons/FileIcon';
import { editFileName, editLongFileName } from '../../../../../../utils/fileNameEditor';
import ImgPopup from '../../../../ImgPopup/ImgPopup';


export default function CommentFiles(props) {

  const [isOpenFullImg, setIsOpenFullImg] = useState(false);
  const [link, setLink] = useState('');

  const handleClickOpenImg = () => {
    setIsOpenFullImg(true)
    document.body.classList.add('modal-show');
  }
  const handleClickCloseImg = (e) => {
    setIsOpenFullImg(false)
    document.body.classList.remove('modal-show');
  }

  return (
    <div className={styles.filesWrapper}>
      <div className={classNames('flex', styles.files, styles.docs)}>
        {
          props.filesList?.filter(item => !['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).map((item) =>
            <div
              key={item.id}
              className={classNames('flex', styles.file)}
            >
              <a
                href={item.file}
                target="_blank"
                download
                rel="noreferrer"
                className={classNames('flex', styles.fileLink)}>
                {!props.editFile && <FileIcon />} {editLongFileName(item.name)}
              </a>
              {
                props.editFile &&
                <button
                  onClick={() => props.handleDeleteFile(props.filesList, item)}
                  type="button"
                  className={classNames('flex', styles.deleteFileBtn)}
                >
                  <span className={classNames(styles.horLine, styles.line)}></span>
                  <span className={classNames(styles.verLine, styles.line)}></span>
                </button>
              }
            </div>
          )
        }
      </div>
      <div className={classNames('flex', styles.files, styles.images)}>
        {
          props.filesList?.filter(item => ['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).map((item) =>
            <div
              key={item.id}
              className={classNames('flex', 'imageBox', styles.image, props.editFile ? styles.editImg : styles.openImg)}
              onClick={() => {
                !props.editFile && setLink(item.file)
                !props.editFile && handleClickOpenImg()
              }}
            >
              <img
                src={item.file}
              />
              {
                props.editFile &&
                <button
                  onClick={() => props.handleDeleteFile(props.filesList, item)}
                  type="button"
                  className={classNames('flex', styles.deleteFileBtn)}
                >
                  <span className={classNames(styles.horLine, styles.line)}></span>
                  <span className={classNames(styles.verLine, styles.line)}></span>
                </button>
              }
            </div>
          )
        }
        {
          isOpenFullImg &&
          <ImgPopup
            file={link}
            handleClickClose={handleClickCloseImg}
            insideParent={props.insideParent}
          />
        }
      </div>
    </div>
  );
}
