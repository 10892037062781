import classNames from 'classnames';
import React, { useState } from 'react';
import DeleteIcon from '../../../../../../../../svgIcons/DeleteIcon';
import ConfirmWindow from '../../../../../../../common/ConfirmWindow/ConfirmWindow';
import styles from './archivedcolscontrols.module.css';
import { socket } from '../../../../../../../../API/socket';
import FromArchiveIcon from '../../../../../../../../svgIcons/FromArchiveIcon';

export default function ArchivedColsControls(props) {

  const [isOpenControls, setIsOpenControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [actionKey, setActionKey] = useState('');

  const handleClickOpenConfirm = () => {
    setIsOpenConfirmPopup(true)
    document.body.classList.add('modal-show');
  }

  const handleClickCloseConfirm = () => {
    setIsOpenConfirmPopup(false)
    document.body.classList.remove('modal-show');
    setActionKey('')
  }

  const handleDeleteColumn = () => {
    socket.emit('deleteColumn', JSON.stringify(props.colId))
    handleClickCloseConfirm()
  }

  const handleRestoreColumn = () => {
    socket.emit('ArchiveColumn',
      {
        columnId: props.colId,
        archived: false
      })
    handleClickCloseConfirm()
  }

  return (
    <div className={classNames('flex', 'iconBtnGroup', styles.columnControls)}>
      <button
        onClick={() => {
          setActionKey('deleteColumn')
          handleClickOpenConfirm()
        }}
        className={classNames('flex', 'iconBtn')}
      >
        <DeleteIcon />
      </button>
      <button
        onClick={() => {
          setActionKey('restoreColumn')
          handleClickOpenConfirm()
        }}
        className={classNames('flex', 'iconBtn')}
      >
        <FromArchiveIcon />
      </button>
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          title={
            actionKey === 'deleteColumn' ?
              'Удалить колонку?'
              : 'Разархивировать колонку?'}
          btnText={actionKey === 'restoreColumn' ? 'Применить' : false}
          onDelete={actionKey === 'deleteColumn' ? handleDeleteColumn : handleRestoreColumn}
          closeDelete={handleClickCloseConfirm}
        />
      }
    </div>
  );
}
