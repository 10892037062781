import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserControls from './UserControls';
import { getMe, logout } from '../../../../redux/authReducer';
import { selectMe } from '../../../../redux/authSelectors';
import { useNavigate } from 'react-router-dom';

export default function UserControlsContainer(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleClick = (() => {
    dispatch(logout())
    .then(() => navigate(`/login/`))
  })

  const me = useSelector(selectMe)

  return (
    <UserControls
      width={props.width}
      isFull={props.isFull}
      handleClick={handleClick}
      me={me}
    />
  );
}
